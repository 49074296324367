import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {UserDto} from '@domain/auth/user.dto';
import {KeycloakService} from 'keycloak-angular';
import {first, firstValueFrom, Observable, tap} from 'rxjs';
import {environment} from '../../../environments/environment';
import {RegisterRequest} from '../domain/auth/register-request';
import {CurrentUser} from './current-user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUser = inject(CurrentUser);
  private keycloak = inject(KeycloakService);
  private http = inject(HttpClient);

  get isLogged() {
    return this.keycloak.isLoggedIn();
  }

  async user() {
    if (this.currentUser.isAnonymous) {
      return await firstValueFrom(this.me());
    }

    return this.currentUser.user;
  }

  login() {
    return this.keycloak.login();
  }

  logout() {
    this.currentUser.set(null);

    return this.keycloak.logout();
  }

  register(request: RegisterRequest): Observable<UserDto> {
    return this.http.post<UserDto>(environment.api.bff + '/users', request).pipe(first());
  }

  me(): Observable<UserDto> {
    return this.http.get<UserDto>(environment.api.bff + '/users/me').pipe(
      first(),
      tap(user => this.currentUser.set(user))
    );
  }
}
