import {NgOptimizedImage} from '@angular/common';
import {booleanAttribute, ChangeDetectionStrategy, Component, inject, input, LOCALE_ID} from '@angular/core';
import {RouterLink} from '@angular/router';
import {BadgeModule} from 'primeng/badge';
import {ButtonModule} from 'primeng/button';
import {DividerModule} from 'primeng/divider';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {RippleModule} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {HasRoleDirective} from '../../directives/security/has-role.directive';
import {MediaDto, MediaFormGroup} from '../../domain/media/media.dto';
import {MultiLangValueDto} from '../../domain/media/multi-lang-value.dto';
import {FileSizePipe} from '../../pipes/upload/file-size.pipe';
import {AuthService} from '../../services/auth.service';
import {CartStore} from '../../stores/cart.store';
import {LocaleUtil} from '../../utils/locale.util';
import {MediaUtil} from '../../utils/media.util';

@Component({
  selector: 'croisi-navigation',
  standalone: true,
  imports: [
    NgOptimizedImage,
    DividerModule,
    HasRoleDirective,
    ButtonModule,
    RippleModule,
    HasRoleDirective,
    RouterLink,
    BadgeModule,
    OverlayPanelModule,
    TableModule,
    TooltipModule,
    FileSizePipe,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  cartStore = inject(CartStore);
  home = input(false, {transform: booleanAttribute});
  protected readonly MediaUtil = MediaUtil;
  private localeToken = inject(LOCALE_ID);
  private authService = inject(AuthService);

  localized(media: MediaDto, field: keyof MediaFormGroup): MultiLangValueDto[] {
    return media[field];
  }

  localizedField(media: MediaDto, field: keyof MediaFormGroup) {
    const language = LocaleUtil.ofLocaleToken(this.localeToken);

    return this.localized(media, field).find(f => f.lang === language).value;
  }

  logout() {
    this.authService.logout();
  }

  clear(overlay: OverlayPanel) {
    this.cartStore.clear();
    overlay.hide();
  }

  download(overlay: OverlayPanel) {
    this.cartStore.download();
    overlay.hide();
  }
}
