<div class=" flex  min-w-[64rem] h-[36rem] bg-white">
  <div
    class="flex flex-col items-center border-solid border-y-0 border-l-0 border-r-2 border-grey-500 w-56 bg-grey-50 gap-2 cursor-pointer py-4 overflow-auto">
    @for (media of medias().controls; track $index) {
      <div class="relative flex flex-col w-40 group" (click)="selectMedia($index)">
        <div class="border-solid rounded-xl flex flex-col p-2 bg-grey-50"
             [class.border-transparent]="$index !== selectedMedia()"
             [class.border-emphasis-primary]="$index === selectedMedia()">
          @if (DOCUMENT_MEDIA_TYPES.includes(media.value.type)) {
            <div class="flex items-center justify-center">
              <croisi-svg class="h-20 w-20 text-red-500" namespace="bo-icons" icon="ce-document" />
            </div>
          } @else if (VIDEO_MEDIA_TYPES.includes(media.value.type)) {
            <div class="flex items-center justify-center">
              <i class="text-7xl pi pi-youtube text-red-500"></i>
            </div>
          } @else {
            <img [src]="store.getUploadedFile($index).objectURL" class="object-contain h-24 rounded-xl" />
          }
        </div>
        <span class="mt-1 whitespace-nowrap overflow-hidden overflow-ellipsis text-grey-600 font-semibold">
          {{ localizedField(media, 'name') }}
        </span>
      </div>
    }
  </div>

  @if (selectedMedia() !== undefined) {
    <div class="flex flex-col gap-2 p-3 h-[36rem] grow overflow-x-hidden overflow-y-auto">
      <croisi-edit-basic-media-info [media]="media" [index]="selectedMedia()" />
      <croisi-edit-characteristics-media-info [media]="media" [index]="selectedMedia()" />
    </div>
  }
</div>

