import {environment} from '../../../environments/environment';
import {ImageFormatEnum} from '../domain/media/image-format.enum';
import {VIDEO_MEDIA_TYPES} from '../domain/media/media-type.enum';
import {MediaDto} from '../domain/media/media.dto';

export class MediaUtil {
  static YOUTUBE_ID_REGEX = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
  static YOUTUBE_THUMBNAIL_URL = 'https://img.youtube.com/vi/{id}/hqdefault.jpg';

  static extractYoutubeThumbnailUrl = (videoUrl: string): string => {
    const id = MediaUtil.YOUTUBE_ID_REGEX.exec(videoUrl)?.[1] ?? null;

    return MediaUtil.YOUTUBE_THUMBNAIL_URL.replace('{id}', id);
  };

  static previewUrl(media: MediaDto): string {
    if (VIDEO_MEDIA_TYPES.includes(media.type)) {
      return this.extractYoutubeThumbnailUrl(media.videoUrl);
    }

    const previewFormat = media?.availableFormats?.findIndex(a => ImageFormatEnum.VIGNETTE === a.format);

    if (previewFormat !== -1) {
      return `${environment.api.bff}/medias/${media.id}/${ImageFormatEnum.VIGNETTE}`;
    }

    return `${environment.api.bff}/medias/${media.id}/${ImageFormatEnum.ORIGINAL}`;
  }

  static cartUrl(media: MediaDto): string {
    const previewFormat = media?.availableFormats?.findIndex(
      a => ImageFormatEnum.R16_9 === a.format && a.dimensions === '200x112'
    );

    if (previewFormat !== -1) {
      return `${environment.api.bff}/medias/${media.id}/${ImageFormatEnum.R16_9}/200x112`;
    }

    return `${environment.api.bff}/medias/${media.id}/${ImageFormatEnum.ORIGINAL}`;
  }

  static originalUrl(media: MediaDto): string {
    return `${environment.api.bff}/medias/${media.id}/${ImageFormatEnum.ORIGINAL}`;
  }
}
