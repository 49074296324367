export const environment = {
  production: true,
  environment: 'PREPROD',
  api: {
    media: 'https://images-preprod.croisieurope.com',
    bff: '/api',
  },
  keycloak: {
    url: 'https://auth-preprod.croisieurope.com',
    realm: 'croisi',
    client: 'croisi-medialib-web',
  },
};
