import {computed, Injectable, signal} from '@angular/core';
import {saveAs} from 'file-saver';
import * as JSZip from 'jszip';
import {MediaDto} from '../domain/media/media.dto';
import {MediaUtil} from '../utils/media.util';

@Injectable({
  providedIn: 'root',
})
export class CartStore {
  _cart = signal<MediaDto[]>([]);

  totalFileSize = computed(() =>
    this._cart()
      .map(m => m.weight)
      .reduce((acc, weight) => acc + weight, 0)
  );

  get cart() {
    return this._cart() ?? [];
  }

  toggle(media: MediaDto) {
    if (this.isInCart(media)) {
      this.remove(media);
    } else {
      this.add(media);
    }
  }

  add(media: MediaDto) {
    this._cart.update(oldValues => [...oldValues, media]);
  }

  remove(media: MediaDto) {
    this._cart.update(oldValues => oldValues.filter(m => m.id !== media.id));
  }

  isInCart(media: MediaDto) {
    return this._cart().some(m => m.id === media.id);
  }

  clear() {
    this._cart.set([]);
  }

  async download() {
    let zip = new JSZip();

    for (const media of this._cart()) {
      const blob = await this.downloadImage(media);
      const buffer = await blob.arrayBuffer();

      zip = zip.file(media.originalFilename + '-' + Math.random(), buffer);
    }

    zip.generateAsync({type: 'blob'}).then(content => {
      saveAs(content, 'images.zip');

      this.clear();
    });
  }

  async downloadImage(media: MediaDto) {
    return await fetch(MediaUtil.originalUrl(media)).then(r => r.blob());
  }
}
