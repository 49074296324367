import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {AbstractId} from '../generic/abstract-id';
import {AvailableFormatDto} from './available-format.dto';
import {MediaTypeEnum} from './media-type.enum';
import {MultiLangValueDto, MultiLangValueForm} from './multi-lang-value.dto';

export class MediaDto extends AbstractId {
  mimeType: string;
  name: MultiLangValueDto[];
  alt: MultiLangValueDto[];
  author: string;
  description: MultiLangValueDto[];
  internal: boolean;
  type: MediaTypeEnum;
  lang: string;
  videoUrl: string;
  property: string;
  weight: number;
  originalWidth: number;
  originalHeight: number;
  originalFilename: string;
  regionCodes: string[];
  countryCodes: string[];
  riverCodes: string[];
  seaCodes: string[];
  channelCodes: string[];
  excursion: boolean;
  boats: string[];
  tags: string[];
  availableFormats: AvailableFormatDto[];
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export interface MediaFormGroup {
  mimeType?: FormControl<string>;
  name?: FormArray<FormGroup<MultiLangValueForm>>;
  alt?: FormArray<FormGroup<MultiLangValueForm>>;
  author?: FormControl<string>;
  description?: FormArray<FormGroup<MultiLangValueForm>>;
  internal?: FormControl<boolean>;
  type?: FormControl<MediaTypeEnum>;
  lang?: FormControl<string>;
  videoUrl?: FormControl<string>;
  property?: FormControl<string>;
  weight?: FormControl<number>;
  originalWidth?: FormControl<number>;
  originalHeight?: FormControl<number>;
  originalFilename?: FormControl<string>;
  regionCodes?: FormControl<string[]>;
  countryCodes?: FormControl<string[]>;
  riverCodes?: FormControl<string[]>;
  seaCodes?: FormControl<string[]>;
  channelCodes?: FormControl<string[]>;
  excursion?: FormControl<boolean>;
  boats?: FormControl<string[]>;
  tags?: FormControl<string[]>;

  /* NOT SAVED FRONT ONLY */
  takeAt?: FormControl<number>;
}
