import {HttpClient} from '@angular/common/http';
import {inject, Injectable, signal} from '@angular/core';
import {catchError, EMPTY, finalize, first, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ReferentialDto} from '../domain/referential/referential.dto';

@Injectable({
  providedIn: 'root',
})
export class ReferentialService {
  static WS_PATH = 'referentials';
  protected _loading = signal(false);
  private http = inject(HttpClient);

  getAll(lang: string) {
    this._loading.set(true);
    return this.http.get<ReferentialDto>(environment.api.bff + `/${ReferentialService.WS_PATH}/${lang}`).pipe(
      first(),
      catchError(() => EMPTY),
      finalize(() => this._loading.set(false))
    );
  }

  autocomplete(referential: string, search: string): Observable<string[]> {
    this._loading.set(true);
    return this.http
      .get<string[]>(environment.api.bff + `/${ReferentialService.WS_PATH}/autocomplete/${referential}`, {
        params: {
          search,
        },
      })
      .pipe(
        first(),
        catchError(() => EMPTY),
        finalize(() => this._loading.set(false))
      );
  }
}
