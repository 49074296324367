@if (!globalStore.iframe()) {
  <croisi-navigation />
}

<div [formGroup]="queryForm" class="flex">
  <div #searchContainer [style.height.px]="window.innerHeight - searchContainer.getBoundingClientRect().top"
       class="sticky top-0 left-0 h-screen overflow-auto w-96 border border-solid border-transparent border-r-2 border-r-grey-500 bg-grey-50">
    <div
      class="flex flex-col justify-center h-20 bg-white border border-solid border-transparent border-b-2 border-b-grey-500 px-4 gap-2">
      <span class="text-black font-bold" i18n>Refine your search</span>
      <button (click)="reset()"
              class="p-button-text underline p-0 text-emphasis-primary w-fit text-sm" i18n pButton>
        Reset
      </button>
    </div>

    <div *hasRole="'INTERNE'"
         class="flex flex-col gap-2 p-4 border border-solid border-transparent border-b-2 border-b-grey-500">
      <span class="text-black font-bold text-sm" i18n>Media upload date</span>
      <span class="text-black font-semibold text-xs" i18n>From</span>
      <p-calendar [showIcon]="true" formControlName="createdAfter" iconDisplay="input"
                  placeholder="dd/mm/yyyy" styleClass="w-full" />
      <span class="text-black font-semibold text-xs" i18n>To</span>
      <p-calendar [showIcon]="true" formControlName="createdBefore" iconDisplay="input"
                  placeholder="dd/mm/yyyy" styleClass="w-full" />
    </div>

    <div *hasRole="'INTERNE'"
         class="flex flex-col gap-2 p-4 border border-solid border-transparent border-b-2 border-b-grey-500">
      <div>
        <p-checkbox [binary]="true" formControlName="createdByMe" />
        <label class="ml-3 text-sm text-grey-700" i18n>My upload</label>
      </div>
    </div>

    <div class="flex flex-col gap-2 p-4 border border-solid border-transparent border-b-2 border-b-grey-500">
      <span class="text-black font-bold text-sm" i18n>Destination</span>
      <span class="text-black font-semibold text-xs" i18n>Regions</span>
      <p-multiSelect [options]="referentialStore.regions"
                     [showClear]="true" appendTo="body" formControlName="regionCodes"
                     i18n-placeholder="All regions" optionLabel="label" optionValue="code"
                     placeholder="All regions" styleClass="w-full" />

      <span class="text-black font-semibold text-xs" i18n>Countries</span>
      <p-multiSelect [options]="referentialStore.countries"
                     [showClear]="true" appendTo="body" formControlName="countryCodes"
                     i18n-placeholder="All regions" optionLabel="label" optionValue="code"
                     placeholder="All countries" styleClass="w-full" />

      <span class="text-black font-semibold text-xs" i18n>Rivers</span>
      <p-multiSelect [options]="referentialStore.rivers"
                     [showClear]="true" appendTo="body" formControlName="riverCodes"
                     i18n-placeholder="All regions" optionLabel="label" optionValue="code"
                     placeholder="All rivers" styleClass="w-full" />

      <span class="text-black font-semibold text-xs" i18n>Seas</span>
      <p-multiSelect [options]="referentialStore.seas"
                     [showClear]="true" appendTo="body" formControlName="seaCodes"
                     i18n-placeholder="All regions" optionLabel="label" optionValue="code"
                     placeholder="All seas" styleClass="w-full" />

      <span class="text-black font-semibold text-xs" i18n>Channels</span>
      <p-multiSelect [options]="referentialStore.channels"
                     [showClear]="true" appendTo="body" formControlName="channelCodes"
                     i18n-placeholder="All regions" optionLabel="label" optionValue="code"
                     placeholder="All channels" styleClass="w-full" />
    </div>

    <div class="flex flex-col gap-2 p-4 border border-solid border-transparent border-b-2 border-b-grey-500">
      <label class="text-blue-900 font-semibold text-sm" for="mediaType" i18n>Media Type</label>
      <p-multiSelect [options]="mediaTypes" [showClear]="true"
                     class="w-full rounded-md"
                     formControlName="types" i18n-placeholder="All media types"
                     id="mediaType" placeholder="All media types" styleClass="w-full mr-4">
        <ng-template let-item pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <croisi-svg [icon]="getMediaDataByType(item).picto" class="h-5 w-6" namespace="bo-icons" />
            <div>{{ getMediaDataByType(item).label }}</div>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="flex align-items-center gap-2">
            <croisi-svg [icon]="getMediaDataByType(item).picto" class="h-5 w-6" namespace="bo-icons" />
            <div>{{ getMediaDataByType(item).label }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>

    <div class="flex flex-col gap-2 p-4 border border-solid border-transparent border-b-2 border-b-grey-500">
      <label class="text-blue-900 font-semibold text-xs" for="properties">Properties</label>
      <p-autoComplete (completeMethod)="searchReferential('PROPERTY', $event)" [multiple]="true"
                      [suggestions]="referentialStore.properties"
                      appendTo="body" formControlName="properties"
                      i18n-placeholder="All properties" id="properties"
                      placeholder="All properties" styleClass="w-full">
      </p-autoComplete>
    </div>

    <div class="flex flex-col gap-2 p-4 border border-solid border-transparent border-b-2 border-b-grey-500">
      <label class="text-blue-900 font-semibold text-sm" for="boats">Boats</label>
      <p-multiSelect [options]="referentialStore.boats" [showClear]="true"
                     appendTo="body" formControlName="boats" i18n-placeholder="All boats"
                     id="boats" optionLabel="label"
                     optionValue="label" placeholder="All boats" styleClass="w-full" />
    </div>

    <div class="flex flex-col gap-2 p-4 border border-solid border-transparent border-b-2 border-b-grey-500">
      <div>
        <p-checkbox [binary]="true" formControlName="excursion" />
        <label class="ml-3 text-sm text-grey-700" i18n>Media is related to excursion</label>
      </div>
    </div>

    <div *hasRole="'INTERNE'" class="flex items-center gap-2 p-4">
      <p-inputSwitch formControlName="internal" />
      <span class="text-black text-sm" i18n>Internal</span>
    </div>

  </div>
  <div class="grow flex flex-col px-8">
    <div class="flex flex-col justify-center items-center gap-2 py-3">
      <div class="flex items-center gap-4">
        <div
          class="w-fit h-12 flex items-center justify-center bg-white rounded-md p-2 border border-solid border-grey-500">
          <i class="pi pi-search"></i>

          <input class="border-0 outline-0 min-w-96"
                 formControlName="globalSearch"
                 pInputText placeholder="Search"
                 type="text" />
        </div>

        <p-dropdown [options]="availableSorts" [showClear]="true" appendTo="body"
                    formControlName="sort" i18n-placeholder="Sort by relevance"
                    optionLabel="label" optionValue="value"
                    placeholder="Sort by relevance" styleClass="w-full" />
      </div>
      <span i18n>{{ store.totalRecord }} result(s) found</span>
    </div>
    <croisi-gallery search />
  </div>
</div>
