import {NgOptimizedImage} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, inject, LOCALE_ID} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {Params, Router, RouterLink} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import {DividerModule} from 'primeng/divider';
import {DialogService} from 'primeng/dynamicdialog';
import {IconFieldModule} from 'primeng/iconfield';
import {InputIconModule} from 'primeng/inputicon';
import {InputTextModule} from 'primeng/inputtext';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {RippleModule} from 'primeng/ripple';
import {SkeletonModule} from 'primeng/skeleton';
import {GalleryComponent} from '../core/components/gallery/gallery.component';
import {NavigationComponent} from '../core/components/navigation/navigation.component';
import {InfiniteScrollNearEndDirective} from '../core/directives/infinite-scroll-near-end.directive';
import {HasRoleDirective} from '../core/directives/security/has-role.directive';
import {SearchFilterService} from '../core/services/search-filter.service';
import {MediaStore} from '../core/stores/media.store';
import {LocaleUtil} from '../core/utils/locale.util';
import {UploadMediaModalComponent} from '../upload/upload-media-modal/upload-media-modal.component';

@Component({
  selector: 'croisi-home',
  standalone: true,
  imports: [
    NavigationComponent,
    NgOptimizedImage,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    DividerModule,
    ButtonModule,
    RippleModule,
    InfiniteScrollNearEndDirective,
    SkeletonModule,
    RouterLink,
    GalleryComponent,
    OverlayPanelModule,
    HasRoleDirective,
    FormsModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MediaStore],
})
export class HomeComponent {
  private localeToken = inject(LOCALE_ID);
  private dialog = inject(DialogService);
  private searchFilterService = inject(SearchFilterService);
  private router = inject(Router);

  filters = toSignal(this.searchFilterService.getAll(LocaleUtil.ofLocaleToken(this.localeToken)));

  MAX_VISIBLE_FILTER = 10;
  store = inject(MediaStore);

  term: string;

  constructor() {
    this.store.load();
  }

  getVisibleFilters() {
    return this.filters()?.slice(0, this.MAX_VISIBLE_FILTER) ?? [];
  }

  getHiddenFilters() {
    return this.filters()?.slice(this.MAX_VISIBLE_FILTER) ?? [];
  }

  upload() {
    this.dialog.open(UploadMediaModalComponent, {
      styleClass: 'p-croisi-upload-dialog',
      showHeader: false,
    });
  }

  search() {
    this.router.navigate(['search'], {queryParams: {globalSearch: this.term}});
  }

  queryParams(params: string): Params {
    const httpParams = new HttpParams({fromString: params});

    const queryParams: Params = {};
    httpParams.keys().forEach(key => {
      queryParams[key] = httpParams.get(key);
    });

    return queryParams;
  }
}
