import {inject, Injectable, signal} from '@angular/core';
import {finalize} from 'rxjs';
import {LabelDto} from '../domain/referential/label.dto';
import {LangDto} from '../domain/referential/lang.dto';
import {ReferentialDto} from '../domain/referential/referential.dto';
import {TranslationDto} from '../domain/referential/translation.dto';
import {ReferentialService} from '../services/referential.service';

@Injectable({
  providedIn: 'root',
})
export class ReferentialStore {
  protected _loading = signal<boolean>(true);
  protected _referentials = signal<ReferentialDto>(undefined);
  protected _tags = signal<string[]>([]);
  protected _properties = signal<string[]>([]);
  private referentialService = inject(ReferentialService);

  get referential(): ReferentialDto {
    return this._referentials();
  }

  get loading(): boolean {
    return this._loading();
  }

  get regions(): TranslationDto[] {
    return this._referentials()?.regions;
  }

  get countries(): TranslationDto[] {
    return this._referentials()?.countries;
  }

  get rivers(): TranslationDto[] {
    return this._referentials()?.rivers;
  }

  get seas(): TranslationDto[] {
    return this._referentials()?.seas;
  }

  get channels(): TranslationDto[] {
    return this._referentials()?.channels;
  }

  get boats(): LabelDto[] {
    return this._referentials()?.boats;
  }

  get langs(): LangDto[] {
    return [
      {code: 'FR', label: $localize`French`},
      {code: 'EN', label: $localize`English`},
      {code: 'ES', label: $localize`Spanish`},
      {code: 'NL', label: $localize`Dutch`},
      {code: 'DE', label: $localize`German`},
    ];
  }

  get tags(): string[] {
    return this._tags() ?? [];
  }

  get properties(): string[] {
    return this._properties() ?? [];
  }

  autocomplete(referential: 'PROPERTY' | 'TAGS', search: string, readonly?: boolean) {
    return this.referentialService.autocomplete(referential, search).subscribe(suggestions => {
      const options = readonly ? suggestions : [search, ...suggestions];

      switch (referential) {
        case 'PROPERTY':
          this._properties.set(options);
          break;
        case 'TAGS':
          this._tags.set(options);
          break;
      }
    });
  }

  load(lang: string) {
    this._loading.set(true);
    this.referentialService
      .getAll(lang)
      .pipe(finalize(() => this._loading.set(false)))
      .subscribe(referentials => this._referentials.set(referentials));
  }
}
