import {ArrayUtil, ObjectUtil, StringUtil} from '@030_croisieurope/croisi-front-library';
import {Injectable, signal} from '@angular/core';
import {UserDto} from '@domain/auth/user.dto';

@Injectable({
  providedIn: 'root',
})
export class CurrentUser {
  private _currentUser = signal<UserDto>(null);

  get isAnonymous() {
    return ObjectUtil.isNullOrUndefined(this._currentUser());
  }

  get user() {
    return this._currentUser();
  }

  get id() {
    return this._currentUser()?.id;
  }

  get email() {
    return this._currentUser()?.email;
  }

  get roles() {
    return this._currentUser()?.roles ?? [];
  }

  set(user: UserDto) {
    this._currentUser.set(user);
  }

  hasRole(roleNames: string[], user: UserDto): boolean {
    if (ObjectUtil.isNullOrUndefined(user)) {
      return false;
    }

    const roleNamesCleaned = roleNames.map(r => r.trim()).filter(StringUtil.isNotBlank);

    if (ArrayUtil.isEmpty(roleNamesCleaned) || this.isAdmin(user)) {
      return true;
    }

    const userRoleName = user.roles.map(r => r.name);

    return roleNamesCleaned.every(roleName => userRoleName.includes(roleName));
  }

  hasOrRole(roleNames: string[], user: UserDto): boolean {
    if (ObjectUtil.isNullOrUndefined(user)) {
      return false;
    }

    const roleNamesCleaned = roleNames.map(r => r.trim()).filter(StringUtil.isNotBlank);

    if (ArrayUtil.isEmpty(roleNamesCleaned) || this.isAdmin(user)) {
      return true;
    }

    const userRoleName = user.roles.map(r => r.name);

    return roleNamesCleaned.some(roleName => userRoleName.includes(roleName));
  }

  private isAdmin(user: UserDto) {
    if (ObjectUtil.isNullOrUndefined(user)) {
      return false;
    }

    if (ArrayUtil.isEmpty(user.roles)) {
      return false;
    }

    return user.roles.findIndex(r => r.name === 'GLOBAL_ADMIN') !== -1;
  }
}
