import {MediaFamilyTypeEnum} from './media-family-type.enum';
import {MediaTypeEnum} from './media-type.enum';

export enum AuthorizedMimeTypeEnum {
  PNG = 'PNG',
  JPG = 'JPG',
  TIFF = 'TIFF',
  SVG = 'SVG',
  PDF = 'PDF',
}

export interface AuthorizedMineTypeInfo {
  mimeType: string;
  metadata: boolean;
  mediaType: MediaTypeEnum;
  familyType: MediaFamilyTypeEnum;
}

export const MimeTypeData: Record<AuthorizedMimeTypeEnum, AuthorizedMineTypeInfo> = {
  [AuthorizedMimeTypeEnum.JPG]: {
    mimeType: 'image/jpeg',
    metadata: true,
    familyType: MediaFamilyTypeEnum.IMAGE,
    mediaType: MediaTypeEnum.PHOTO,
  },
  [AuthorizedMimeTypeEnum.PNG]: {
    mimeType: 'image/png',
    metadata: true,
    familyType: MediaFamilyTypeEnum.IMAGE,
    mediaType: MediaTypeEnum.PHOTO,
  },
  [AuthorizedMimeTypeEnum.TIFF]: {
    mimeType: 'image/tiff',
    metadata: true,
    familyType: MediaFamilyTypeEnum.IMAGE,
    mediaType: MediaTypeEnum.PHOTO,
  },
  [AuthorizedMimeTypeEnum.SVG]: {
    mimeType: 'image/svg+xml',
    metadata: true,
    familyType: MediaFamilyTypeEnum.IMAGE,
    mediaType: MediaTypeEnum.MAP,
  },
  [AuthorizedMimeTypeEnum.PDF]: {
    mimeType: 'application/pdf',
    metadata: false,
    familyType: MediaFamilyTypeEnum.DOCUMENT,
    mediaType: MediaTypeEnum.DOCUMENT,
  },
};

export const findAuthorizedMineTypeByMineType = (mineType: string): AuthorizedMineTypeInfo => {
  return Object.entries(MimeTypeData).find(([key, value]) => value.mimeType === mineType)[1];
};
