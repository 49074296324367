import {inject} from '@angular/core';
import {CanActivateFn, RedirectCommand, Router, UrlTree} from '@angular/router';
import {GlobalStore} from '../stores/global.store';

export const iframeGuard: CanActivateFn = (route, state): boolean | UrlTree | RedirectCommand => {
  const router = inject(Router);
  const globalStore = inject(GlobalStore);

  if (route.routeConfig.path !== 'search' && globalStore.iframe()) {
    router.navigate(['search']);
  }

  return true;
};
