<div class="flex flex-col h-full min-w-96">
  <div class="flex items-center justify-between w-full bg-blue-900 rounded-t-2xl px-6 py-4">
    <span class="font-bold text-white" i18n>Upload new media(s)</span>
    <i (click)="close()" class="pi pi-times text-2xl text-emphasis-primary cursor-pointer"></i>
  </div>

  <div class="flex flex-col gap-2 px-8 pt-8">
    <span class="text-blue-600 font-bold uppercase" i18n>Medias</span>
    <div class="flex items-center justify-center w-full h-full bg-white">
      <p-fileUpload (onSelect)="upload($event)" [accept]="store.mimeTypes" [customUpload]="true" [multiple]="true" class="grow"
                    name="medias" styleClass="p-croisi-fileupload">
        <ng-template pTemplate="header"></ng-template>
        <ng-template let-chooseCallback="chooseCallback" pTemplate="content">
          <div
            class="flex flex-col items-center justify-center gap-8 py-6 px-6 rounded-2xl shadow-md bg-white mx-auto">
            <button (click)="choose(chooseCallback)" class="p-button-rounded p-button-info" pButton
                    pRipple>
              <croisi-svg class="w-6 h-6" icon="ce-file_arrow_up" namespace="bo-icons" />
              <span class="ml-2" i18n>Add media(s)</span>
            </button>

            <div class="flex items-center gap-x-2 text-emphasis-primary">
              <croisi-svg class="w-6 h-6" icon="ce-drop" namespace="bo-icons" />
              <span i18n>Drag and Drop</span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="file"></ng-template>
        <ng-template pTemplate="empty"></ng-template>
        <ng-template pTemplate="toolbar"></ng-template>
      </p-fileUpload>
    </div>
  </div>

  <div class="flex flex-col gap-2 mt-4 px-8 pb-8">
    <span class="text-blue-600 font-bold uppercase" i18n>Video</span>
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-youtube" />
      <input [formControl]="youtubeUrl" class="w-full" i18n-placeholder="Youtube URL" pInputText
             placeholder="Youtube URL" type="text" />
    </p-iconField>
  </div>
</div>
