import {computed, inject, Injectable, signal} from '@angular/core';
import {finalize, first} from 'rxjs';
import {MediaSearchDto} from '../domain/media/media-search.dto';
import {MediaDto} from '../domain/media/media.dto';
import {PageWithBuckets} from '../domain/rest/page-with-buckets';
import {MediaService} from '../services/media.service';

@Injectable({
  providedIn: 'root',
})
export class MediaStore {
  protected defaultPageable = Object.assign(new MediaSearchDto(), {
    page: 0,
    size: 50,
  } as MediaSearchDto);
  protected _search = signal<MediaSearchDto>(this.defaultPageable);
  protected _loading = signal<boolean>(true);
  protected _page = signal<PageWithBuckets<MediaDto>>(null);
  protected _medias = signal<MediaDto[]>([]);
  private mediaService = inject(MediaService);
  private _queryParams = computed(() => this.mediaService.buildQueryParams(this._search()));

  get queryParams(): string {
    return this._queryParams();
  }

  get loading(): boolean {
    return this._loading();
  }

  get page(): PageWithBuckets<MediaDto> {
    return this._page();
  }

  get records(): MediaDto[] {
    return this._page()?.content ?? [];
  }

  get medias(): MediaDto[] {
    return this._medias() ?? [];
  }

  get totalMedias(): number {
    return this._medias()?.length ?? 0;
  }

  get totalRecord(): number {
    return this._page()?.totalElements ?? 0;
  }

  get search(): MediaSearchDto {
    return this._search();
  }

  load() {
    this.loadMedias().subscribe(page => {
      this._page.set(page);
      this._medias.set(page.content);
    });
  }

  loadNextPage() {
    if (!this.loading && this.hasNextPage()) {
      this._search.update(search => {
        return {
          ...search,
          page: search.page + 1,
        };
      });

      this.loadMedias().subscribe(page => {
        this._page.set(page);
        this._medias.update(currentMedias => [...currentMedias, ...page.content]);
      });
    }
  }

  hasNextPage(): boolean {
    return !this.page.last;
  }

  searchMedias(search: MediaSearchDto) {
    this._search.update(oldSearch => {
      return {
        ...search,
        page: 0,
        size: oldSearch.size,
      };
    });
    this.load();
  }

  reset() {
    this._search.set(null);
    this._page.set(null);
    this._medias.set([]);
  }

  private loadMedias() {
    this._loading.set(true);
    return this.mediaService.search(this.search).pipe(
      first(),
      finalize(() => this._loading.set(false))
    );
  }
}
