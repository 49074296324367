<div [class.bg-blue-900]="!home()"
     class="flex items-center h-16 px-6 gap-x-2 w-full">
  <img routerLink="/" height="36" ngSrc="/assets/img/logo_croisitek.svg" class="cursor-pointer" priority width="85" />

  <p-divider layout="vertical" styleClass="h-8" />

  <button
    class="bg-inherit border-solid border-transparent border-2 text-white p-2 cursor-pointer font-bold hover:border-b-emphasis-primary"
    i18n routerLink="/">
    Gallery
  </button>

  <button *hasRole="'ADMIN'"
          class="bg-inherit border-solid border-transparent border-2 text-white p-2 cursor-pointer font-bold hover:border-b-emphasis-primary"
          i18n>
    Admin
  </button>

  <div class="ml-auto flex gap-x-3">
    <button (click)="cart.toggle($event)" class="p-button-rounded bg-white text-black border-none" icon="pi pi-shopping-cart" pButton
            pRipple></button>
    <button *hasRole="'INTERNE'" class="p-button-rounded bg-white text-black border-none" icon="pi pi-trash" pButton
            pRipple></button>
    <button (click)="logout()" class="p-button-rounded bg-white text-black border-none" icon="pi pi-sign-out" pButton
            pRipple></button>
  </div>
</div>


<p-overlayPanel #cart styleClass="p-croisi-overlay">
  <ng-template pTemplate="content">
    <div class="flex items-center justify-between w-full bg-blue-900 px-6 py-4">
      <span class="font-bold text-white" i18n>Cart</span>

      @if (cartStore.cart.length > 0) {
        <div class="flex items-center gap-x-2 ml-auto">
          <span class="text-xs text-white">{{ cartStore.totalFileSize() | fileSize }}</span>
          <button (click)="clear(cart)"
                  pButton pRipple icon="pi pi-eraser" pTooltip="Empty cart" i18n-pTooltip="Empty cart"
                  class="p-button-rounded bg-emphasis-primary text-white border-none"></button>
          <button (click)="download(cart)"
                  pButton pRipple icon="pi pi-download" pTooltip="Download" i18n-pTooltip="Download"
                  class="p-button-rounded bg-emphasis-primary text-white border-none"></button>
        </div>
      }

      <i (click)="cart.hide()" class="ml-8 pi pi-times text-2xl text-emphasis-primary cursor-pointer"></i>
    </div>
    <p-table [paginator]="true" [rows]="5"
             [showCurrentPageReport]="true" [totalRecords]="cartStore.cart.length"
             [value]="cartStore.cart" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             dataKey="id" editMode="row"
             loadingIcon="pi pi-spin pi-spinner"
             styleClass="p-datatable-striped p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th i18n>Preview</th>
          <th i18n>Name</th>
          <th i18n></th>
        </tr>
      </ng-template>
      <ng-template let-media pTemplate="body">
        <tr>
          <td>
            <img [height]="50" [src]="MediaUtil.cartUrl(media)" [width]="89">
          </td>
          <td class="max-w-96 overflow-hidden overflow-ellipsis whitespace-nowrap">
            {{ localizedField(media, 'name') }}
          </td>
          <td>
            <div class="flex justify-end">
              <button (click)="cartStore.remove(media)"
                      class="p-button-icon p-button-text text-booking-canceled" icon="pi pi-trash"
                      pButton pRipple>
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
