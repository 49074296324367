@if (media() !== undefined) {
  <div class="flex flex-col gap-2" [formGroup]="media()">
    <span class="text-sm font-medium text-blue-900 text-md uppercase">Media {{ index() + 1 }}</span>
    @if (isImageOrDocument) {
      <div class="flex justify-between items-center mt-2">
        <span class="text-xs text-grey-600" i18n>
          Size :
          <span class="font-medium text-blue-900">
            {{ media().value.weight | fileSize }}
          </span>
        </span>
        @if (isImage) {
          <span class="text-xs text-grey-600" i18n>
            Dimension :
            <span class="font-medium text-blue-900">
              {{ media().value.originalWidth }} x {{ media().value.originalHeight }}
            </span>
          </span>
        }
        <span class="text-xs text-grey-600" i18n>
          Date :
          <span class="font-medium text-blue-900">
            {{ media().value.takeAt | date: 'dd/MM/yyyy' }}
          </span>
        </span>
      </div>
    }

    <div class="flex flex-col gap-2 mt-3">
      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="name" i18n>Name</label>
        <input class="w-full rounded-md" [formControl]="formArrayFieldLocalizedFormControl('name')" id="name"
               pInputText />
      </div>
      <div class="flex gap-2">
        <div class="flex flex-col gap-2 grow">
          <label class="text-blue-900 font-semibold text-xs" for="alt">Alt</label>
          <input class="w-full rounded-md" [formControl]="formArrayFieldLocalizedFormControl('alt')" id="alt"
                 pInputText />
        </div>
        <div class="flex flex-col gap-2 grow">
          <label class="text-blue-900 font-semibold text-xs" for="author" i18n>Author</label>
          <input class="w-full rounded-md" formControlName="author" id="author"
                 pInputText />
        </div>
      </div>
      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="description" i18n>Description</label>
        <input class="w-full rounded-md" [formControl]="formArrayFieldLocalizedFormControl('description')"
               id="description" pInputText />
      </div>

      <div class="flex items-center gap-2">
        <p-inputSwitch formControlName="internal" />
        <span class="text-blue-900 text-sm" i18n>Internal</span>
      </div>
    </div>
  </div>
}
