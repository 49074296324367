import {ChangeDetectionStrategy, Component, inject, input, model, OnInit, output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {CommonMessageLocalized} from '@i18n/common-message-localized';
import {isEmpty, isNil} from 'lodash';
import {ButtonDirective} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {Ripple} from 'primeng/ripple';
import {EditBasicMediaInfoComponent} from '../../core/components/edit-basic-media-info/edit-basic-media-info.component';
import {EditCharacteristicsMediaInfoComponent} from '../../core/components/edit-characteristics-media-info/edit-characteristics-media-info.component';
import {MediaDto, MediaFormGroup} from '../../core/domain/media/media.dto';
import {MultiLangValueDto} from '../../core/domain/media/multi-lang-value.dto';
import {MediaService} from '../../core/services/media.service';
import {LocaleUtil} from '../../core/utils/locale.util';

@Component({
  selector: 'croisi-edit-media-modal',
  standalone: true,
  imports: [DialogModule, EditBasicMediaInfoComponent, EditCharacteristicsMediaInfoComponent, ButtonDirective, Ripple],
  templateUrl: './edit-media-modal.component.html',
  styleUrl: './edit-media-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMediaModalComponent implements OnInit {
  media = input.required<MediaDto>();
  edit = model.required<boolean>();
  activeLocale = model.required<string>();
  updated = output();
  protected readonly CommonMessageLocalized = CommonMessageLocalized;
  private fb = inject(FormBuilder);
  form = this.fb.group<MediaFormGroup>({
    takeAt: new FormControl<number>(null),
    mimeType: new FormControl(''),
    name: new FormArray([]),
    alt: new FormArray([]),
    author: new FormControl(''),
    description: new FormArray([]),
    internal: new FormControl(true),
    type: new FormControl(null),
    lang: new FormControl(''),
    videoUrl: new FormControl(''),
    property: new FormControl('', [Validators.required]),
    weight: new FormControl<number>(null),
    originalFilename: new FormControl(''),
    originalWidth: new FormControl<number>(null),
    originalHeight: new FormControl<number>(null),
    regionCodes: new FormControl([]),
    countryCodes: new FormControl([]),
    riverCodes: new FormControl([]),
    seaCodes: new FormControl([]),
    channelCodes: new FormControl([]),
    excursion: new FormControl(false),
    boats: new FormControl([]),
    tags: new FormControl([]),
  });
  private mediaService = inject(MediaService);

  ngOnInit() {
    this.form.patchValue({
      ...this.media(),
    });

    this.buildLocalized(this.form.controls.name, this.media().name);
    this.buildLocalized(this.form.controls.alt, this.media().alt);
    this.buildLocalized(this.form.controls.description, this.media().description);
  }

  toggleEdit() {
    this.edit.update(v => !v);
  }

  save() {
    const media = Object.assign(new MediaDto(), {
      ...this.media(),
      ...this.form.value,
      name: this.clearEmptyLocalized(this.form.controls.name),
      alt: this.clearEmptyLocalized(this.form.controls.alt),
      description: this.clearEmptyLocalized(this.form.controls.description),
    } as MediaDto);

    this.mediaService.update(this.media().id, null, media).subscribe(() => {
      this.edit.set(false);
      this.updated.emit();
    });
  }

  buildLocalized(localized: FormArray, multiValues: MultiLangValueDto[] = []) {
    localized.clear();

    const allLocale = LocaleUtil.LOCALES;

    for (let i = 0; i < allLocale.length; i++) {
      const locale = allLocale[i];
      const multiValue = multiValues.find(v => v.lang === locale);

      if (multiValue) {
        localized.push(this.buildMultiValueForm(multiValue));
      } else {
        localized.push(this.buildLocalizedForm(locale));
      }
    }
  }

  buildMultiValueForm(multiValue: MultiLangValueDto) {
    return this.fb.group({
      lang: new FormControl(multiValue.lang),
      value: new FormControl(multiValue.value),
    });
  }

  buildLocalizedForm(locale: string) {
    return this.fb.group({
      lang: new FormControl(locale),
      value: new FormControl(''),
    });
  }

  clearEmptyLocalized(localized: FormArray): MultiLangValueDto[] {
    return localized.value.filter(i => this.isNotEmptyLocalized(i));
  }

  isNotEmptyLocalized(multiValue: MultiLangValueDto): boolean {
    return Object.entries(multiValue)
      .filter(([key, value]) => !['lang'].includes(key))
      .some(([key, value]) => !isNil(value) && !isEmpty(value));
  }
}
