import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {SvgComponent} from '@components/svg/svg.component';
import {UploadMediaStore} from '@stores/upload-media.store';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileSelectEvent, FileUploadModule} from 'primeng/fileupload';
import {IconFieldModule} from 'primeng/iconfield';
import {InputIconModule} from 'primeng/inputicon';
import {InputTextModule} from 'primeng/inputtext';
import {RippleModule} from 'primeng/ripple';
import {MediaStore} from '../../core/stores/media.store';
import {UploadMediaSettingsModalComponent} from '../upload-media-settings-modal/upload-media-settings-modal.component';

@Component({
  selector: 'croisi-upload-media-modal',
  standalone: true,
  imports: [
    DialogModule,
    CheckboxModule,
    FileUploadModule,
    RippleModule,
    SvgComponent,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ReactiveFormsModule,
  ],
  templateUrl: './upload-media-modal.component.html',
  styleUrl: './upload-media-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadMediaModalComponent {
  static YOUTUBE_REGEX = new RegExp('^(https?:\\/\\/)?((www\\.)?youtube\\.com|youtu\\.be)\\/.+$', 'g');
  store = inject(UploadMediaStore);
  mediaStore = inject(MediaStore);
  youtubeUrl = new FormControl<string>('');
  private ref = inject(DynamicDialogRef);
  private dialog = inject(DialogService);

  constructor() {
    this.youtubeUrl.valueChanges.subscribe(url => {
      if (UploadMediaModalComponent.YOUTUBE_REGEX.test(url)) {
        this.goToSettings();
      }
    });
  }

  async upload(selectEvent: FileSelectEvent) {
    await this.store.setUploadedMedias(selectEvent.currentFiles);

    this.goToSettings();

    this.ref.close();
  }

  goToSettings() {
    this.dialog
      .open(UploadMediaSettingsModalComponent, {
        styleClass: 'p-croisi-upload-dialog',
        showHeader: false,
        data: {
          youtubeUrl: this.youtubeUrl.value,
        },
      })
      .onClose.subscribe(() => {
        this.mediaStore.load();
      });
  }

  close() {
    this.ref.close();
  }

  choose(chooseCallback: () => void) {
    chooseCallback();
  }
}
