import {Routes} from '@angular/router';
import {authGuard} from './core/guards/auth.guard';
import {iframeGuard} from './core/guards/iframe.guard';
import {HomeComponent} from './home/home.component';
import {MediaComponent} from './media/media.component';
import {RegisterComponent} from './register/register.component';
import {SearchComponent} from './search/search.component';

export const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [authGuard, iframeGuard]},
  {path: 'search', component: SearchComponent, canActivate: [authGuard, iframeGuard]},
  {path: 'medias/:id', component: MediaComponent, canActivate: [authGuard, iframeGuard]},
  {path: 'register', component: RegisterComponent, canActivate: [iframeGuard]},
];
