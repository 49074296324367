import {ChangeDetectionStrategy, Component, input} from '@angular/core';

@Component({
  selector: 'croisi-svg',
  templateUrl: './svg.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SvgComponent {
  namespace = input<'icons' | 'bo-icons' | 'technical-icons'>('icons');
  icon = input.required<string>();

  class = input<string>('');
}
