import {CdkCopyToClipboard} from '@angular/cdk/clipboard';
import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, LOCALE_ID, signal} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  DOCUMENT_MEDIA_TYPES,
  IMAGE_MEDIA_TYPES,
  MediaTypeData,
  MediaTypeEnum,
  VIDEO_MEDIA_TYPES,
} from '@domain/media/media-type.enum';
import {MediaUtil} from '@utils/media.util';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ButtonDirective} from 'primeng/button';
import {ChipModule} from 'primeng/chip';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {InputTextModule} from 'primeng/inputtext';
import {PaginatorModule} from 'primeng/paginator';
import {ProgressBarModule} from 'primeng/progressbar';
import {Ripple} from 'primeng/ripple';
import {environment} from '../../environments/environment';
import {EditBasicMediaInfoComponent} from '../core/components/edit-basic-media-info/edit-basic-media-info.component';
import {EditCharacteristicsMediaInfoComponent} from '../core/components/edit-characteristics-media-info/edit-characteristics-media-info.component';
import {LanguageTabMenuComponent} from '../core/components/language-tab-menu/language-tab-menu.component';
import {NavigationComponent} from '../core/components/navigation/navigation.component';
import {SvgComponent} from '../core/components/svg/svg.component';
import {HasRoleDirective} from '../core/directives/security/has-role.directive';
import {MimeTypeData} from '../core/domain/media/authorized-mime-type-enum';
import {ImageFormatData, ImageFormatEnum, ImageFormatInfo} from '../core/domain/media/image-format.enum';
import {MediaFamilyTypeEnum} from '../core/domain/media/media-family-type.enum';
import {MediaDto, MediaFormGroup} from '../core/domain/media/media.dto';
import {MultiLangValueDto} from '../core/domain/media/multi-lang-value.dto';
import {CommonMessageLocalized} from '../core/i18n/common-message-localized';
import {FileSizePipe} from '../core/pipes/upload/file-size.pipe';
import {MediaService} from '../core/services/media.service';
import {CartStore} from '../core/stores/cart.store';
import {LocaleUtil} from '../core/utils/locale.util';
import {CroppingMediaModalComponent} from './cropping-media-modal/cropping-media-modal.component';
import {EditMediaModalComponent} from './edit-media-modal/edit-media-modal.component';

@Component({
  selector: 'croisi-media',
  standalone: true,
  imports: [
    NavigationComponent,
    LanguageTabMenuComponent,
    ButtonDirective,
    Ripple,
    DatePipe,
    FileSizePipe,
    SvgComponent,
    ChipModule,
    DividerModule,
    CdkCopyToClipboard,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    DialogModule,
    EditBasicMediaInfoComponent,
    EditCharacteristicsMediaInfoComponent,
    ProgressBarModule,
    CroppingMediaModalComponent,
    EditMediaModalComponent,
    CroppingMediaModalComponent,
    HasRoleDirective,
  ],
  templateUrl: './media.component.html',
  styleUrl: './media.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaComponent {
  private localeToken = inject(LOCALE_ID);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private mediaService = inject(MediaService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);

  cartStore = inject(CartStore);

  media = signal<MediaDto>(null);
  edit = signal<boolean>(false);
  cropping = signal<boolean>(false);
  format = signal<ImageFormatEnum>(ImageFormatEnum.ORIGINAL);
  randomNumber = signal<number>(Math.random());
  activeLocale = signal<string>(LocaleUtil.ofLocaleToken(this.localeToken));

  constructor() {
    this.load();
  }

  get url() {
    if (VIDEO_MEDIA_TYPES.includes(this.media().type)) {
      return this.media().videoUrl;
    }

    const dimensions = this.availableDimensionsForFormat(this.format());

    if (![ImageFormatEnum.ORIGINAL, ImageFormatEnum.VIGNETTE].includes(this.format()) && dimensions?.length > 0) {
      return `${environment.api.media}/medias/${this.media().id}/${this.format()}/${dimensions.at(0)}?random=${this.randomNumber()}`;
    }

    return `${environment.api.media}/medias/${this.media().id}/${this.format()}?random=${this.randomNumber()}`;
  }

  get mediaUrl() {
    if (VIDEO_MEDIA_TYPES.includes(this.media().type)) {
      return this.media().videoUrl;
    }

    const dimensions = this.availableDimensionsForFormat(this.format());

    if (this.format() !== ImageFormatEnum.ORIGINAL && dimensions?.length > 0) {
      return `${environment.api.media}/medias/${this.media().id}/${this.format()}/${dimensions.at(0)}`;
    }

    return `${environment.api.media}/medias/${this.media().id}/${this.format()}`;
  }

  get formatInfos(): ImageFormatInfo[] {
    return Object.values(ImageFormatData).filter(i => i.visible && this.availableFormats.includes(i.format));
  }

  get availableFormats(): ImageFormatEnum[] {
    return Array.from(new Set(this.media().availableFormats?.map(f => f.format)));
  }

  get isPhoto() {
    return MediaTypeEnum.PHOTO === this.media().type;
  }

  get isImageType() {
    return IMAGE_MEDIA_TYPES.includes(this.media().type);
  }

  get isNotOriginalImage() {
    return this.isPhoto && ImageFormatEnum.ORIGINAL !== this.format();
  }

  get name() {
    return this.media().name.find(l => l.lang === this.activeLocale())?.value ?? '';
  }

  get mimeTypes() {
    return Object.entries(MimeTypeData)
      .filter(([key, info]) => MediaFamilyTypeEnum.IMAGE === info.familyType)
      .map(([key, info]) => info.mimeType)
      .join(',');
  }

  getMediaDataByType(type: MediaTypeEnum) {
    return MediaTypeData[type];
  }

  formatUrl(format: ImageFormatEnum): string {
    const dimensions = this.availableDimensionsForFormat(format);

    if (![ImageFormatEnum.ORIGINAL, ImageFormatEnum.VIGNETTE].includes(format) && dimensions?.length > 0) {
      return `${environment.api.media}/medias/${this.media().id}/${format}/${dimensions.at(0)}`;
    }

    return `${environment.api.media}/medias/${this.media().id}/${format}`;
  }

  load(forceImageRefresh = false) {
    this.mediaService.get(this.route.snapshot.paramMap.get('id')).subscribe(media => {
      this.media.set(media);

      if (forceImageRefresh) {
        this.randomNumber.set(Math.random());
      }
    });
  }

  availableDimensionsForFormat(format: ImageFormatEnum) {
    return this.media()
      .availableFormats?.filter(f => f.format === format)
      ?.map(f => f.dimensions);
  }

  selectFormat(format: ImageFormatEnum) {
    this.format.set(format);
  }

  delete(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      header: CommonMessageLocalized.DELETE_CONFIRM_HEADER,
      message: CommonMessageLocalized.DELETE_CONFIRM,
      accept: () => {
        this.mediaService.delete(this.media().id).subscribe(() => {
          this.router.navigate(['']);
        });
      },
    });
  }

  crop() {
    this.cropping.set(true);
  }

  replace(upload: HTMLInputElement) {
    upload.click();

    upload.onchange = (event: any) => {
      const file = event.target.files[0];

      this.mediaService.update(this.media().id, file).subscribe(() => {
        this.load(true);
      });
    };
  }

  cropped() {
    this.randomNumber.set(Math.random());
  }

  updated() {
    this.load();
  }

  copyToClipboard() {
    this.messageService.add({
      severity: 'success',
      detail: $localize`Copied to clipboard`,
    });
  }

  toggleEdit() {
    this.edit.update(v => !v);
  }

  localized(media: MediaDto, field: keyof MediaFormGroup): MultiLangValueDto[] {
    return media[field];
  }

  localizedField(media: MediaDto, field: keyof MediaFormGroup) {
    const language = LocaleUtil.ofLocaleToken(this.localeToken);

    return this.localized(media, field).find(f => f.lang === language)?.value;
  }

  protected readonly MediaUtil = MediaUtil;
  protected readonly VIDEO_MEDIA_TYPES = VIDEO_MEDIA_TYPES;
  protected readonly DOCUMENT_MEDIA_TYPES = DOCUMENT_MEDIA_TYPES;
}
