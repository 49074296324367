import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import {CommonMessageLocalized} from '@i18n/common-message-localized';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {ButtonDirective} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {Ripple} from 'primeng/ripple';
import {environment} from '../../../environments/environment';
import {ImageFormatData, ImageFormatEnum} from '../../core/domain/media/image-format.enum';
import {MediaDto} from '../../core/domain/media/media.dto';
import {UpdateMediaFormatRequest} from '../../core/domain/media/update-media-format-request';
import {MediaService} from '../../core/services/media.service';

@Component({
  selector: 'croisi-cropping-media-modal',
  standalone: true,
  imports: [DialogModule, ImageCropperComponent, ButtonDirective, Ripple],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './cropping-media-modal.component.html',
  styleUrl: './cropping-media-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CroppingMediaModalComponent {
  cropping = model.required<boolean>();
  media = input.required<MediaDto>();
  format = input.required<ImageFormatEnum>();
  cropSize = signal<ImageCroppedEvent>(undefined);
  cropped = output();
  protected readonly CommonMessageLocalized = CommonMessageLocalized;
  private mediaService = inject(MediaService);

  get loading() {
    return this.mediaService.loading;
  }

  get url() {
    return `${environment.api.bff}/medias/${this.media().id}/${ImageFormatEnum.ORIGINAL}`;
  }

  get ratio() {
    return ImageFormatData[this.format()].ratio;
  }

  get minCropperSize() {
    const dimensions = this.availableDimensionsForFormat;

    let minWidth = 0;
    let minHeight = 0;
    for (const dimension of dimensions) {
      const [width, height] = dimension.split('x').map(i => parseInt(i, 10));

      const originalWidth = this.media().originalWidth;
      const originalHeight = this.media().originalHeight;

      if (originalWidth >= width && originalHeight >= height) {
        minWidth = width;
        minHeight = height;

        break;
      }
    }

    return {minWidth, minHeight};
  }

  get availableDimensionsForFormat() {
    return this.media()
      .availableFormats?.filter(f => f.format === this.format())
      ?.map(f => f.dimensions);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.cropSize.set(event);
  }

  close() {
    this.cropping.set(false);
  }

  crop() {
    const {width, height, imagePosition} = this.cropSize();
    const {x1, y1} = imagePosition;

    const request = Object.assign(new UpdateMediaFormatRequest(), {
      format: this.format(),
      x: x1,
      y: y1,
      width,
      height,
    } as UpdateMediaFormatRequest);

    this.mediaService.crop(this.media().id, request).subscribe(() => {
      this.cropping.set(false);
      this.cropped.emit();
    });
  }
}
