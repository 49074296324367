import {StringUtil} from '@030_croisieurope/croisi-front-library';
import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {LOCALIZED_MEDIA_TYPES} from '../../domain/media/media-type.enum';
import {MediaFormGroup} from '../../domain/media/media.dto';

export const uploadMediaValidator = (): ValidatorFn => {
  return (form: FormGroup<MediaFormGroup>): ValidationErrors | null => {
    const mediaType = form.value.type;
    const lang = form.value.lang;

    if (LOCALIZED_MEDIA_TYPES.includes(mediaType) && StringUtil.isBlank(lang)) {
      return {langRequired: true};
    }

    return null;
  };
};
