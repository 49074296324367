import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  ONE_MO_IN_OCTET = 1048576;

  transform(value: number): string {
    return `${(value / this.ONE_MO_IN_OCTET).toFixed(2)} Mo`;
  }
}
