<div class="flex bg-blue-900 h-8 w-fit">
  @for (locale of locales(); track locale) {
    <button (click)="setActive(locale)" pButton pRipple
            [class.bg-emphasis-primary]="locale === active()"
            class="flex items-center justify-center h-full border-none rounded-none text-white bg-blue-900 p-2">
      {{ locale }}
    </button>

  }
</div>
