import {StringUtil} from '@030_croisieurope/croisi-front-library';
import {DatePipe, JsonPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  LOCALE_ID,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {isEmpty, isNil} from 'lodash';
import {MessageService, SharedModule} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';
import {RippleModule} from 'primeng/ripple';
import {ScrollerModule} from 'primeng/scroller';
import {ToastModule} from 'primeng/toast';
import {delay, finalize, forkJoin, Observable} from 'rxjs';
import {SvgComponent} from '../../core/components/svg/svg.component';
import {
  AuthorizedMineTypeInfo,
  findAuthorizedMineTypeByMineType,
} from '../../core/domain/media/authorized-mime-type-enum';
import {MediaFamilyTypeEnum} from '../../core/domain/media/media-family-type.enum';
import {MediaTypeEnum} from '../../core/domain/media/media-type.enum';
import {MediaDto, MediaFormGroup} from '../../core/domain/media/media.dto';
import {MultiLangValueDto} from '../../core/domain/media/multi-lang-value.dto';
import {CommonMessageLocalized} from '../../core/i18n/common-message-localized';
import {MediaService} from '../../core/services/media.service';
import {UploadMediaStore} from '../../core/stores/upload-media.store';
import {LocaleUtil} from '../../core/utils/locale.util';
import {uploadMediaValidator} from '../../core/validators/upload/upload-media-validator';
import {UploadMediaSettingsBulkEditComponent} from '../upload-media-settings-bulk-edit/upload-media-settings-bulk-edit.component';
import {UploadMediaSettingsComponent} from '../upload-media-settings/upload-media-settings.component';

interface Dimension {
  width: number;
  height: number;
}

@Component({
  selector: 'croisi-upload-media-settings-modal',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DialogModule,
    RippleModule,
    SharedModule,
    FormsModule,
    ScrollerModule,
    InputSwitchModule,
    InputTextModule,
    ReactiveFormsModule,
    DatePipe,
    DropdownModule,
    SvgComponent,
    MultiSelectModule,
    ChipsModule,
    UploadMediaSettingsBulkEditComponent,
    JsonPipe,
    UploadMediaSettingsComponent,
    ProgressBarModule,
    ToastModule,
  ],
  templateUrl: './upload-media-settings-modal.component.html',
  styleUrl: './upload-media-settings-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadMediaSettingsModalComponent implements OnInit {
  store = inject(UploadMediaStore);
  selectedMedia = signal<number>(0);
  bulkEdit = signal(false);
  mixedMedia = signal(false);
  videoMedia = signal(false);
  uploading = signal(false);
  uploaded = signal(0);
  progress = computed(() => ((this.uploaded() / this.store.uploadedMedias?.length) * 100).toFixed(0));
  mediaFamilyTypes = new Set<MediaFamilyTypeEnum>();
  protected readonly CommonMessageLocalized = CommonMessageLocalized;
  private ref = inject(DynamicDialogRef);
  private config = inject(DynamicDialogConfig);
  private fb = inject(FormBuilder);
  medias = this.fb.array<FormGroup<MediaFormGroup>>([]);
  private cdRef = inject(ChangeDetectorRef);
  private mediaService = inject(MediaService);
  private messageService = inject(MessageService);
  private localeToken = inject(LOCALE_ID);

  async ngOnInit() {
    const isVideo = !!this.config.data.youtubeUrl;

    if (isVideo) {
      const media = await this.addMediaGroup(null, this.config.data.youtubeUrl);

      this.medias.push(media);

      this.videoMedia.set(true);
    } else {
      for (const file of this.store.uploadedMedias) {
        const media = await this.addMediaGroup(file);

        this.medias.push(media);
      }

      if (Array.from(this.mediaFamilyTypes.keys()).length > 1) {
        this.mixedMedia.set(true);
      }
    }

    this.cdRef.detectChanges();
  }

  cancel() {
    this.ref.close();
  }

  async save() {
    const uploaded: Observable<MediaDto>[] = [];

    for (let i = 0; i < this.medias.controls.length; i++) {
      const mediaControl = this.medias.controls[i];
      const file = this.store.uploadedMedias[i];

      const media = Object.assign(new MediaDto(), {
        ...mediaControl.value,
        name: this.clearEmptyLocalized(mediaControl.controls.name),
        description: this.clearEmptyLocalized(mediaControl.controls.description),
        alt: this.clearEmptyLocalized(mediaControl.controls.alt),
      } as MediaDto);

      uploaded.push(this.mediaService.upload(file, media).pipe(finalize(() => this.uploaded.update(v => v + 1))));
    }

    this.uploading.set(true);
    forkJoin([...uploaded])
      .pipe(
        delay(1000),
        finalize(() => this.uploading.set(false))
      )
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: CommonMessageLocalized.SUCCESS,
          detail: $localize`Uploaded`,
        });

        this.ref.close();
      });
  }

  nextMedia() {
    this.selectedMedia.update(v => v + 1);
  }

  previousMedia() {
    this.selectedMedia.update(v => v - 1);
  }

  async addMediaGroup(file: File, videoUrl?: string) {
    const language = LocaleUtil.ofLocaleToken(this.localeToken);

    let fileNameWithoutExtension = '';
    let dimension: Dimension;
    let mineTypeInfo: AuthorizedMineTypeInfo;

    if (file) {
      mineTypeInfo = findAuthorizedMineTypeByMineType(file.type);

      this.mediaFamilyTypes.add(mineTypeInfo.familyType);

      fileNameWithoutExtension = file.name.split('.')[0];

      if (mineTypeInfo.metadata) {
        dimension = await new Promise<Dimension>((resolve, reject) => {
          const img = new Image();

          img.onload = () => {
            const width = img.width;
            const height = img.height;
            resolve({width, height} as Dimension);
          };
          img.onerror = reject;
          img.src = URL.createObjectURL(file);
        });
      }
    }

    const localeName = Object.assign(new MultiLangValueDto(), {
      lang: language,
      value: fileNameWithoutExtension,
    } as MultiLangValueDto);

    const form = this.fb.group<MediaFormGroup>(
      {
        takeAt: new FormControl(file?.lastModified),
        mimeType: new FormControl(file?.type),
        name: new FormArray([]),
        alt: new FormArray([]),
        author: new FormControl(''),
        description: new FormArray([]),
        internal: new FormControl(true),
        type: new FormControl(StringUtil.isNotBlank(videoUrl) ? MediaTypeEnum.VIDEO : mineTypeInfo?.mediaType),
        lang: new FormControl(''),
        videoUrl: new FormControl(videoUrl),
        property: new FormControl('', [Validators.required]),
        weight: new FormControl(file?.size),
        originalFilename: new FormControl(fileNameWithoutExtension),
        originalWidth: new FormControl(dimension?.width ?? null),
        originalHeight: new FormControl(dimension?.height ?? null),
        regionCodes: new FormControl([]),
        countryCodes: new FormControl([]),
        riverCodes: new FormControl([]),
        seaCodes: new FormControl([]),
        channelCodes: new FormControl([]),
        excursion: new FormControl(false),
        boats: new FormControl([]),
        tags: new FormControl([]),
      },
      {validators: [uploadMediaValidator()]}
    );

    this.buildLocalized(form.controls.name, [localeName]);
    this.buildLocalized(form.controls.alt);
    this.buildLocalized(form.controls.description);

    return form;
  }

  buildLocalized(localized: FormArray, multiValues: MultiLangValueDto[] = []) {
    localized.clear();

    const allLocale = LocaleUtil.LOCALES;

    for (let i = 0; i < allLocale.length; i++) {
      const locale = allLocale[i];
      const multiValue = multiValues.find(v => v.lang === locale);

      if (multiValue) {
        localized.push(this.buildMultiValueForm(multiValue));
      } else {
        localized.push(this.buildLocalizedForm(locale));
      }
    }
  }

  buildMultiValueForm(multiValue: MultiLangValueDto) {
    return this.fb.group({
      lang: new FormControl(multiValue.lang),
      value: new FormControl(multiValue.value),
    });
  }

  buildLocalizedForm(locale: string) {
    return this.fb.group({
      lang: new FormControl(locale),
      value: new FormControl(''),
    });
  }

  clearEmptyLocalized(localized: FormArray): MultiLangValueDto[] {
    return localized.value.filter(i => this.isNotEmptyLocalized(i));
  }

  isNotEmptyLocalized(multiValue: MultiLangValueDto): boolean {
    return Object.entries(multiValue)
      .filter(([key, value]) => !['lang'].includes(key))
      .some(([key, value]) => !isNil(value) && !isEmpty(value));
  }
}
