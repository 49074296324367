<div [formGroup]="media()" class="w-full flex flex-col gap-2">
  <div class="w-full flex flex-col gap-2 bg-gray-50 rounded-2xl p-4">
    <span class="font-bold text-base text-blue-600" i18n>Media Type</span>
    <div class="flex w-full gap-2">
      <div class="w-1/2 flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="mediaType" i18n>Media Type</label>
        <p-dropdown [options]="mediaTypeByFileType" class="w-full rounded-md"
                    formControlName="type"
                    i18n-placeholder="Select a Media Type" id="mediaType"
                    placeholder="Select a Media Type" styleClass="w-full mr-4">
          <ng-template let-item pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <croisi-svg [icon]="getMediaDataByType(item).picto" class="h-5 w-6" namespace="bo-icons" />
              <span>{{ getMediaDataByType(item).label }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
              <croisi-svg [icon]="getMediaDataByType(item).picto" class="h-5 w-6" namespace="bo-icons" />
              <span>{{ getMediaDataByType(item).label }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      @if (isLocalizedMediaType) {
        <div class="w-1/2 flex flex-col gap-2">
          <label class="text-blue-900 font-semibold text-xs" for="lang" i18n>Lang</label>

          <p-dropdown id="lang" formControlName="lang"
                      [options]="referentialStore.langs" optionLabel="label" optionValue="code"
                      i18n-placeholder="Choose a lang" placeholder="Choose a lang" appendTo="body"
                      styleClass="w-full mr-4" class="w-full rounded-md"
          />
        </div>
      }

      @if (isVideoType) {
        <div class="w-1/2 flex flex-col gap-2">
          <div class="w-full flex flex-col gap-2">
            <label class="text-blue-900 font-semibold text-xs" for="videoUrl" i18n>Video URL</label>
            <input class="w-full rounded-md" formControlName="videoUrl" id="videoUrl"
                   pInputText />
          </div>
        </div>
      }
    </div>
  </div>
  <div class="w-full flex flex-col gap-2 bg-gray-50 rounded-2xl p-4">

    <span class="font-bold text-base text-blue-600" i18n>Property</span>
    <div class="w-1/2 flex flex-col gap-2">
      <label class="text-blue-900 font-semibold text-xs" for="property">Property</label>
      <p-autoComplete (completeMethod)="search('PROPERTY', $event)" [autoOptionFocus]="true"
                      [suggestions]="referentialStore.properties"
                      appendTo="body" formControlName="property"
                      i18n-placeholder="Properties" id="property"
                      placeholder="Properties" styleClass="w-full">
        <ng-template let-index="index" let-property pTemplate="item">
          @if (index === 0) {
            <span i18n>Create new property « {{ property }} »</span>
          } @else {
            <span>{{ property }}</span>
          }
        </ng-template>
      </p-autoComplete>

    </div>
  </div>
  <div class="w-full flex flex-col gap-2 bg-gray-50 rounded-2xl p-4">

    <span class="font-bold text-base text-blue-600" i18n>Destination</span>
    <div class="flex w-full gap-2">
      <div class="w-1/2 flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="regions">Regions</label>
        <p-multiSelect [options]="referentialStore.regions" appendTo="body"
                       formControlName="regionCodes" i18n-placeholder="All" id="regions"
                       optionLabel="label" optionValue="code" placeholder="All"
                       styleClass="w-full">

        </p-multiSelect>
      </div>
      <div class="w-1/2 flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="countries">Countries</label>
        <p-multiSelect [options]="referentialStore.countries" appendTo="body"
                       formControlName="countryCodes" i18n-placeholder="All" id="countries"
                       optionLabel="label" optionValue="code" placeholder="All"
                       styleClass="w-full">

        </p-multiSelect>
      </div>
    </div>

    <div class="flex w-full gap-2">
      <div class="w-1/2 flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="seas">Seas</label>
        <p-multiSelect [options]="referentialStore.seas" formControlName="seaCodes"
                       i18n-placeholder="All" id="seas" optionLabel="label"
                       optionValue="code" placeholder="All"
                       styleClass="w-full">

        </p-multiSelect>
      </div>
      <div class="w-1/2 flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="channels">Channels</label>
        <p-multiSelect [options]="referentialStore.channels" appendTo="body"
                       formControlName="channelCodes" i18n-placeholder="All" id="channels"
                       optionLabel="label" optionValue="code" placeholder="All"
                       styleClass="w-full">

        </p-multiSelect>
      </div>
    </div>

    <div class="w-1/2 flex flex-col gap-2">
      <label class="text-blue-900 font-semibold text-xs" for="rivers">Rivers</label>
      <p-multiSelect [options]="referentialStore.rivers" appendTo="body"
                     formControlName="riverCodes" i18n-placeholder="All" id="rivers"
                     optionLabel="label" optionValue="code" placeholder="All"
                     styleClass="w-full">

      </p-multiSelect>
    </div>

    <div class="flex items-center gap-2">
      <p-inputSwitch formControlName="excursion" />
      <span class="text-blue-900 text-sm" i18n>Media is related to excursion</span>
    </div>
  </div>
  <div class="w-full flex flex-col gap-2 bg-gray-50 rounded-2xl p-4">

    <span class="font-bold text-base text-blue-600" i18n>Boats</span>
    <div class="w-1/2 flex flex-col gap-2">
      <label class="text-blue-900 font-semibold text-xs" for="boats">Boats</label>
      <p-multiSelect [options]="referentialStore.boats" appendTo="body"
                     formControlName="boats" i18n-placeholder="Boats" id="boats"
                     optionLabel="label" optionValue="label"
                     placeholder="Boats" styleClass="w-full" />
    </div>
  </div>
  <div class="w-full flex flex-col gap-2 bg-gray-50 rounded-2xl p-4">

    <span class="font-bold text-base text-blue-600" i18n>Add tags</span>
    <div class="w-full flex flex-col gap-2">
      <label class="text-blue-900 font-semibold text-xs" for="tags">Tags</label>
      <p-autoComplete (completeMethod)="search('TAGS', $event)" [multiple]="true"
                      [suggestions]="referentialStore.tags"
                      appendTo="body" formControlName="tags"
                      i18n-placeholder="Tags" id="tags"
                      placeholder="Tags" styleClass="w-full">
        <ng-template let-index="index" let-tag pTemplate="item">
          @if (index === 0) {
            <span i18n>Create new tag « {{ tag }} »</span>
          } @else {
            <span>{{ tag }}</span>
          }
        </ng-template>
      </p-autoComplete>
    </div>
  </div>
</div>
