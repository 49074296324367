<div class="flex flex-col p-3 gap-3 min-w-[64rem] h-[36rem] bg-white grow mb-16 overflow-auto">
  @for (media of medias().controls; track $index) {
    <div class="flex items-center bg-grey-50 rounded-2xl p-3">
      <div class="relative flex flex-col w-40 p-3">
        <div class=" rounded-xl flex flex-col p-2 bg-grey-50">
          @if (DOCUMENT_MEDIA_TYPES.includes(media.value.type)) {
            <div class="flex items-center justify-center">
              <croisi-svg class="h-20 w-20 text-red-500" namespace="bo-icons" icon="ce-document" />
            </div>
          } @else if (VIDEO_MEDIA_TYPES.includes(media.value.type)) {
          } @else {
            <img [src]="store.getUploadedFile($index).objectURL"
                 class="object-contain h-24 rounded-xl" />
          }
        </div>
        <span class="mt-1 whitespace-nowrap overflow-hidden overflow-ellipsis text-grey-600 font-semibold px-2">
          {{ formArrayFieldLocalizedFormControl($index, 'name').value }}
        </span>
      </div>

      <croisi-edit-basic-media-info [media]="media" [index]="$index" />
    </div>
  }

  <croisi-edit-characteristics-media-info [media]="globalMedia" />
</div>
