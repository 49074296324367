import {ChangeDetectionStrategy, Component, inject, input, LOCALE_ID, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DOCUMENT_MEDIA_TYPES, MediaTypeEnum, VIDEO_MEDIA_TYPES} from '@domain/media/media-type.enum';
import {EditBasicMediaInfoComponent} from '../../core/components/edit-basic-media-info/edit-basic-media-info.component';
import {EditCharacteristicsMediaInfoComponent} from '../../core/components/edit-characteristics-media-info/edit-characteristics-media-info.component';
import {SvgComponent} from '../../core/components/svg/svg.component';
import {MediaFormGroup} from '../../core/domain/media/media.dto';
import {UploadMediaStore} from '../../core/stores/upload-media.store';
import {LocaleUtil} from '../../core/utils/locale.util';

@Component({
  selector: 'croisi-upload-media-settings-bulk-edit',
  standalone: true,
  imports: [ReactiveFormsModule, SvgComponent, EditBasicMediaInfoComponent, EditCharacteristicsMediaInfoComponent],
  templateUrl: './upload-media-settings-bulk-edit.component.html',
  styleUrl: './upload-media-settings-bulk-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadMediaSettingsBulkEditComponent implements OnInit {
  store = inject(UploadMediaStore);
  medias = input.required<FormArray<FormGroup<MediaFormGroup>>>();
  protected readonly VIDEO_MEDIA_TYPES = VIDEO_MEDIA_TYPES;
  protected readonly DOCUMENT_MEDIA_TYPES = DOCUMENT_MEDIA_TYPES;
  private fb = inject(FormBuilder);
  globalMedia = this.fb.group<MediaFormGroup>({
    type: new FormControl(MediaTypeEnum.PHOTO),
    lang: new FormControl(''),
    property: new FormControl('', [Validators.required]),
    regionCodes: new FormControl([]),
    countryCodes: new FormControl([]),
    riverCodes: new FormControl([]),
    seaCodes: new FormControl([]),
    channelCodes: new FormControl([]),
    excursion: new FormControl(false),
    boats: new FormControl([]),
    tags: new FormControl([]),
  });
  private locale = inject(LOCALE_ID);

  ngOnInit() {
    this.globalMedia.valueChanges.subscribe(values => this.updateAll(values));

    this.globalMedia.patchValue({type: this.medias().at(0).value.type});

    this.globalMedia.controls.type.valueChanges.subscribe(() => {
      this.globalMedia.controls.lang.patchValue('');
    });
  }

  updateAll(values: any) {
    for (const control of this.medias().controls) {
      control.patchValue({...values});
    }
  }

  formArrayLocalized(index: number, field: keyof MediaFormGroup) {
    return this.medias().at(index).get(field) as FormArray;
  }

  formArrayFieldLocalizedFormControl(index: number, fieldArray: keyof MediaFormGroup) {
    const language = LocaleUtil.ofLocaleToken(this.locale);

    return this.formArrayLocalized(index, fieldArray)
      .controls.find(f => f.value.lang === language)
      .get('value') as FormControl;
  }
}
