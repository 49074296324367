import {FormControl} from '@angular/forms';

export class MultiLangValueDto {
  lang: string;
  value: string;
}

export interface MultiLangValueForm {
  lang: FormControl<string>;
  value: FormControl<string>;
}
