/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';
import {environment} from './environments/environment';

Sentry.init({
  dsn: 'https://663af1436797d81312af61a3300f349a@o4507061083963392.ingest.de.sentry.io/4507645319250000',
  enabled: environment.production,
  environment: environment.environment,
  release: '0.0.1',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\/ api/, 'localhost'],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
