import {Directive, ElementRef, HostListener, input, OnInit, output} from '@angular/core';

@Directive({
  selector: '[infiniteScrollNearEnd]',
  standalone: true,
})
export class InfiniteScrollNearEndDirective implements OnInit {
  nearEnd = output();
  threshold = input<number>(120);

  private window!: Window;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.window = window;
  }

  @HostListener('window:scroll', ['$event.target'])
  windowScrollEvent(event: KeyboardEvent) {
    const currentScrolledY = this.window.scrollY;

    const viewportHeight = this.window.innerHeight;
    const heightOfElement = this.el.nativeElement.scrollHeight;

    const distanceOfElementAndPage = this.el.nativeElement.getBoundingClientRect().top + window.scrollY;

    const scrollToBottom = heightOfElement + distanceOfElementAndPage - viewportHeight - currentScrolledY;

    if (scrollToBottom < this.threshold()) {
      this.nearEnd.emit();
    }
  }
}
