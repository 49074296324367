import {ChangeDetectionStrategy, Component, inject, LOCALE_ID, model, OnInit} from '@angular/core';
import {SvgComponent} from '@components/svg/svg.component';
import {ButtonModule} from 'primeng/button';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {RippleModule} from 'primeng/ripple';
import {LocaleUtil} from '../../utils/locale.util';

@Component({
  selector: 'croisi-language-tab-menu',
  standalone: true,
  imports: [ButtonModule, RippleModule, OverlayPanelModule, SvgComponent],
  templateUrl: './language-tab-menu.component.html',
  styleUrl: './language-tab-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageTabMenuComponent implements OnInit {
  locales = model<string[]>(LocaleUtil.LOCALES);
  active = model<string>(null);
  private localeToken = inject(LOCALE_ID);

  ngOnInit() {
    const language = LocaleUtil.ofLocaleToken(this.localeToken);

    this.active.set(language);
  }

  setActive(locale: string) {
    this.active.set(locale);
  }
}
