<croisi-navigation />

@if (media()) {
  <div class="flex w-8/12 mx-auto gap-4 p-4">
    <div class="relative grow flex flex-col justify-center items-center gap-2 min-h-[30rem]">
      @if (DOCUMENT_MEDIA_TYPES.includes(media().type)) {
        <div class="flex items-center justify-center h-full w-full bg-grey-50 mb-12">
          <croisi-svg class="h-36 w-36 text-red-500" namespace="bo-icons" icon="ce-document" />
        </div>
      } @else if (VIDEO_MEDIA_TYPES.includes(media().type)) {
        <div class="relative flex items-center justify-center h-full w-full mb-12">
          <img [src]="MediaUtil.previewUrl(media())" class="object-cover w-fit h-fit max-w-full max-h-full">
          <i class="absolute text-9xl pi pi-youtube text-white"></i>
        </div>
      } @else {
        <img [src]="url" class="object-contain w-fit h-fit max-w-full max-h-[27rem] mb-12">
      }

      <div class="absolute bottom-0 flex items-center gap-x-2 w-full h-12">
        @if (cartStore.isInCart(media())) {
          <button (click)="cartStore.remove(media())"
                  pButton pRipple icon="pi pi-cart-minus" class="p-button-rounded p-button-info">
            <span class="ml-2" i18n>Remove from cart</span>
          </button>
        } @else {
          <button (click)="cartStore.add(media())"
                  pButton pRipple icon="pi pi-cart-plus" class="p-button-rounded p-button-info">
            <span class="ml-2" i18n>Add to cart</span>
          </button>
        }

        <a download [href]="mediaUrl">
          <button pButton pRipple icon="pi pi-download"
                  class="p-button-rounded bg-emphasis-secondary">
            <span class="ml-2" i18n>Download</span>
          </button>
        </a>

        <div class="flex gap-x-2 ml-auto" *hasRole="'INTERNE'">
          @if (isNotOriginalImage) {
            <button pButton pRipple (click)="crop()"
                    class="p-button-rounded text-emphasis-secondary bg-inherit border-emphasis-secondary p-2">
              <croisi-svg class="h-5 w-5" namespace="bo-icons" icon="ce-crop" />
            </button>
          } @else if (isImageType) {
            <input #upload type="file" [accept]="mimeTypes" class="hidden" />
            <button pButton pRipple (click)="replace(upload)"
                    class="p-button-rounded text-emphasis-secondary bg-inherit border-emphasis-secondary p-2">
              <croisi-svg class="h-5 w-5" namespace="bo-icons" icon="ce-replace" />
            </button>
          }

          <button pButton pRipple icon="pi pi-trash" (click)="delete($event)"
                  class="p-button-rounded text-emphasis-cancelling bg-inherit border-emphasis-cancelling">
          </button>
        </div>
      </div>
    </div>

    <div class="bg-grey-50 rounded-2xl w-1/3 p-4 ml-auto">
      <div class="flex justify-between">
        <croisi-language-tab-menu [(active)]="activeLocale" />
        <button *hasRole="'INTERNE'" (click)="toggleEdit()" pButton pRipple icon="pi pi-pencil"
                class="p-button-text text-emphasis-secondary">
          <span class="ml-2" i18n>Edit</span>
        </button>
      </div>

      <div class="flex flex-col gap-4 my-4">
        <span class="text-blue-900 font-bold uppercase tracking-wide">
          {{ name }}
        </span>

        <div class="flex gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Type :</span>
          <div class="flex align-items-center gap-2">
            <croisi-svg [icon]="getMediaDataByType(media().type).picto" class="h-5 w-6" namespace="bo-icons" />
            <span class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
              {{ getMediaDataByType(media().type).label }}
            </span>
          </div>
        </div>

        <div class="flex gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Alt :</span>
          <span class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ localizedField(media(), 'alt') }}
          </span>
        </div>

        <div class="flex items-center gap-2 group">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Url :</span>
          <span class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ mediaUrl }}
          </span>
          <div class="group-hover:block hidden">
            <button (click)="copyToClipboard()" pButton pRipple icon="pi pi-copy" [cdkCopyToClipboard]="mediaUrl"
                    class="p-button-rounded text-emphasis-secondary bg-inherit border-emphasis-secondary">
            </button>
          </div>
        </div>

        <div class="flex gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Description :</span>
          <span
            class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ localizedField(media(), 'description') }}
          </span>
        </div>

        <div class="flex gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Original name :</span>
          <span
            class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ media().originalFilename }}
          </span>
        </div>

        <div class="flex gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Author :</span>
          <span
            class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ media().author }}
          </span>
        </div>

        @if (isImageType) {
          <div class="flex gap-2">
            <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap"
                  i18n>Original dimensions :</span>
            <span class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ media().originalWidth }} x {{ media().originalHeight }}</span>
          </div>
        }

        <div class="flex gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Date :</span>
          <span
            class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ media().createdAt | date:'dd/MM/yyyy' }}
          </span>
        </div>

        <div class="flex gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Type :</span>
          <span
            class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ media().type }}
          </span>
        </div>

        @if (isImageType) {
          <div class="flex gap-2">
            <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Size :</span>
            <span class="text-blue-900 font-medium text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ media().weight | fileSize }}
          </span>
          </div>
        }

        <div class="flex flex-col gap-2">
          <span class="text-grey-600 text-sm font-semibold uppercase whitespace-nowrap" i18n>Tags</span>
          <div class="flex gap-2">
            @for (tag of media().tags; track tag) {
              <p-chip styleClass="bg-blue-900 text-white px-2 py-1">{{ tag }}</p-chip>
            }
          </div>
        </div>

      </div>
    </div>
  </div>

  @if (isPhoto) {
    <div class="mx-auto w-8/12 my-4 border border-solid border-grey-500 border-t-transparent border-x-transparent pb-2">
      <span class="text-blue-900 font-bold" i18n>Available formats</span>
    </div>

    <div class="grid grid-cols-8 w-8/12 mx-auto gap-2 mb-8">
      @for (formatInfo of formatInfos; track formatInfo.format) {
        <button (click)="selectFormat(formatInfo.format)"
                [disabled]="availableDimensionsForFormat(formatInfo.format)?.length === 0"
                class="relative flex flex-col justify-center items-center gap-2 p-2 bg-grey-50 rounded-md border-2 border-solid cursor-pointer"
                [class.border-emphasis-primary]="format() === formatInfo.format"
                [class.border-transparent]="format() !== formatInfo.format">
          <div class="flex justify-center items-center h-36">
            <img [src]="formatUrl(formatInfo.format)" class="object-contain w-fit h-fit max-w-full max-h-full">
          </div>
          <span class="capitalize text-blue-900 font-semibold text-center">{{ formatInfo.label }}</span>
        </button>
      }
    </div>
  }

  <croisi-edit-media-modal [media]="media()" (updated)="updated()"
                           [(edit)]="edit" [(activeLocale)]="activeLocale" />

  <croisi-cropping-media-modal [media]="media()" [format]="format()"
                               [(cropping)]="cropping" (cropped)="cropped()" />
}

