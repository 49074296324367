<div class="flex flex-col h-full w-full">
  <div class="relative min-h-56 bg-blue-900/60">
    <img class="absolute w-full h-full object-cover mix-blend-overlay -z-10" fill ngSrc="assets/img/background.jpg"
         priority>
    <croisi-navigation home/>

    <div class="flex items-center justify-center gap-x-4">
      <div class="w-fit h-12 flex items-center justify-center bg-white rounded-md p-2">
        <i class="pi pi-search cursor-pointer" (click)="search()"></i>

        <input [(ngModel)]="term"
          class="border-0 outline-0 min-w-96"
          pInputText placeholder="Search"
          type="text" (keyup.enter)="search()"/>

        <p-divider layout="vertical" styleClass="h-8" />

        <button class="p-button-text p-button-info p-2" i18n pButton pRipple routerLink="search">
          Advanced search
        </button>
      </div>

      <button (click)="upload()" *hasRole="'INTERNE'" class="p-button-rounded p-button-info" icon="pi pi-plus" pButton
              pRipple>
        <span class="ml-2" i18n>Add media(s)</span>
      </button>
    </div>

    <div class="flex justify-center items-center w-8/12 gap-x-4 my-8 mx-auto">
      @for (filter of getVisibleFilters(); track filter.id) {
        <button pButton pRipple class="p-button-text p-button-info p-2 text-white uppercase tracking-wide" i18n
                routerLink="search" [queryParams]="queryParams(filter.query)">
          {{ filter.label }}
        </button>
      }
      @if (filters()?.length > MAX_VISIBLE_FILTER) {
        <button (click)="availableFilterOptions.show($event)" pButton pRipple class="p-button-text text-white">
          <i class="pi pi-angle-right text-white text-2xl"></i>
        </button>

        <p-overlayPanel #availableFilterOptions>
          <div class="flex flex-col items-center justify-center gap-2">
            @for (filter of getHiddenFilters(); track filter.id) {
              <button pButton pRipple
                      class="p-button-text p-button-info p-2 text-blue-900 uppercase tracking-wide w-full" i18n
                      routerLink="search" [queryParams]="queryParams(filter.query)">
                {{ filter.label }}
              </button>
            }
          </div>
        </p-overlayPanel>
      }
    </div>
  </div>

  <croisi-gallery />
</div>
