import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {DOCUMENT_MEDIA_TYPES, VIDEO_MEDIA_TYPES} from '@domain/media/media-type.enum';
import {MediaUtil} from '@utils/media.util';
import {MediaDto} from '../../domain/media/media.dto';
import {GlobalStore} from '../../stores/global.store';
import {SvgComponent} from '../svg/svg.component';

@Component({
  selector: 'croisi-media-preview',
  standalone: true,
  imports: [RouterLink, SvgComponent, NgClass],
  templateUrl: './media-preview.component.html',
  styleUrl: './media-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPreviewComponent {
  globalStore = inject(GlobalStore);

  media = input.required<MediaDto>();
  protected readonly MediaUtil = MediaUtil;
  protected readonly VIDEO_MEDIA_TYPES = VIDEO_MEDIA_TYPES;
  protected readonly DOCUMENT_MEDIA_TYPES = DOCUMENT_MEDIA_TYPES;

  get previewWidth() {
    return this.globalStore.iframe() ? 250 : 400;
  }

  get previewWidthClass() {
    return this.globalStore.iframe() ? 'w-preview-small' : 'w-preview';
  }
}
