import {ArrayUtil, ObjectUtil, StringUtil} from '@030_croisieurope/croisi-front-library';
import {HttpParams} from '@angular/common/http';

export class HttpParamsBuilder {
  private params: HttpParams = new HttpParams();

  append(param: string, value: any): this {
    if (ObjectUtil.isNonNullOrUndefined(value)) {
      if (Array.isArray(value)) {
        return this.appendArray(param, value);
      }

      if (typeof value === 'string') {
        return this.appendString(param, value);
      }

      this.params = this.params.append(param, value);
    }
    return this;
  }

  appendAll(obj: object): this {
    Object.entries(obj).forEach(([key, value]) => {
      this.append(key, value);
    });

    return this;
  }

  build(): HttpParams {
    return this.params;
  }

  private appendString(param: string, value: string) {
    if (StringUtil.isNotBlank(value)) {
      this.params = this.params.append(param, value);
    }

    return this;
  }

  private appendArray(param: string, values: any[]) {
    if (ArrayUtil.isNotEmpty(values)) {
      values.forEach(value => {
        this.append(param, value);
      });
    }

    return this;
  }
}
