import {NgOptimizedImage} from '@angular/common';
import {booleanAttribute, ChangeDetectionStrategy, Component, computed, inject, input, LOCALE_ID} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {DOCUMENT_MEDIA_TYPES, VIDEO_MEDIA_TYPES} from '@domain/media/media-type.enum';
import {ButtonDirective} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {Ripple} from 'primeng/ripple';
import {SkeletonModule} from 'primeng/skeleton';
import {InfiniteScrollNearEndDirective} from '../../directives/infinite-scroll-near-end.directive';
import {HasRoleDirective} from '../../directives/security/has-role.directive';
import {MediaDto, MediaFormGroup} from '../../domain/media/media.dto';
import {MultiLangValueDto} from '../../domain/media/multi-lang-value.dto';
import {CartStore} from '../../stores/cart.store';
import {GlobalStore} from '../../stores/global.store';
import {MediaStore} from '../../stores/media.store';
import {LocaleUtil} from '../../utils/locale.util';
import {MediaPreviewComponent} from '../media-preview/media-preview.component';
import {SvgComponent} from '../svg/svg.component';

@Component({
  selector: 'croisi-gallery',
  standalone: true,
  imports: [
    InfiniteScrollNearEndDirective,
    SkeletonModule,
    NgOptimizedImage,
    CheckboxModule,
    ButtonDirective,
    Ripple,
    RouterLink,
    SvgComponent,
    HasRoleDirective,
    FormsModule,
    MediaPreviewComponent,
  ],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent {
  store = inject(MediaStore);
  cartStore = inject(CartStore);
  globalStore = inject(GlobalStore);
  search = input(false, {transform: booleanAttribute});
  protected readonly VIDEO_MEDIA_TYPES = VIDEO_MEDIA_TYPES;
  protected readonly DOCUMENT_MEDIA_TYPES = DOCUMENT_MEDIA_TYPES;
  private localeToken = inject(LOCALE_ID);

  localized(media: MediaDto, field: keyof MediaFormGroup): MultiLangValueDto[] {
    return media[field];
  }

  localizedField(media: MediaDto, field: keyof MediaFormGroup) {
    const language = LocaleUtil.ofLocaleToken(this.localeToken);

    return this.localized(media, field).find(f => f.lang === language).value;
  }

  addToCart(event: MouseEvent, media: MediaDto) {
    event.stopPropagation();

    this.cartStore.add(media);
  }

  removeFromCart(event: MouseEvent, media: MediaDto) {
    event.stopPropagation();

    this.cartStore.remove(media);
  }

  private partitionBy = (arr: any[], fn: (value: any, index: number, arr: any[]) => any) => [
    ...arr
      .reduce((acc, val, i, arr) => {
        const current = fn(val, i, arr);
        if (acc.has(current)) acc.get(current).push(val);
        else acc.set(current, [val]);
        return acc;
      }, new Map())
      .values(),
  ];

  images = computed(() => {
    const medias = this.store.medias;
    const columns = this.search() ? 3 : 4;

    return this.partitionBy(medias, (_, index) => index % columns);
  });
}
