<p-dialog [(visible)]="edit" [modal]="true">
  <ng-template pTemplate="headless">
    <div class="flex flex-col min-w-[64rem] h-[36rem] rounded-t-2xl">
      <div class="flex items-center justify-between w-full bg-blue-900 rounded-t-2xl px-6 py-4">
        <span class="font-bold text-white" i18n>Edit media</span>
        <i (click)="toggleEdit()" class="pi pi-times text-2xl text-emphasis-primary cursor-pointer"></i>
      </div>

      <div class="flex flex-col gap-2 p-6 h-[36rem] grow overflow-x-hidden overflow-y-auto mb-16 bg-white">
        <croisi-edit-basic-media-info [activeLocale]="activeLocale()" [media]="form" />
        <croisi-edit-characteristics-media-info [media]="form" />
      </div>

      <div
        class="fixed bottom-0 left-0 w-full h-16 flex items-center bg-white rounded-b-2xl gap-x-4 drop-shadow-2xl p-4">
        <button (click)="toggleEdit()" class="p-button-rounded bg-white text-emphasis-cancelling border-emphasis-cancelling ml-auto" i18n
                pButton
                pRipple>
          {{ CommonMessageLocalized.CANCEL }}
        </button>
        <button (click)="save()" [disabled]="form.invalid" class="p-button-rounded p-button-info" pButton
                pRipple>
          {{ CommonMessageLocalized.SAVE }}
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>
