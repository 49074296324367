export class ErrorMessageLocalized {
  // region GlobalError
  static UNEXCEPTED_ERROR = $localize`Unknown error`;
  static BAD_REQUEST = $localize`Bad Request`;
  static MALFORMED_JSON = $localize`Malformed JSON`;
  static VALIDATION_ERROR = $localize`Validation error`;
  static ACCESS_DENIED = $localize`Access denied`;
  static UNSUPPORTED = $localize`An unsupported operation has been attempted`;

  // region DbError
  static ENTITY_NOT_FOUND = $localize`Entity not found`;
  static ENTITY_ALREADY_EXIST = $localize`Entity already exist`;

  // region UsersError
  static USER_NOT_FOUND = $localize`User not found`;
  static USER_ALREADY_EXIST = $localize`User already exist`;
  static ROLE_NOT_FOUND = $localize`Role not found`;
  static ROLE_ALREADY_EXIST = $localize`Role already exist`;
}
