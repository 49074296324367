import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, input, LOCALE_ID} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {PaginatorModule} from 'primeng/paginator';
import {IMAGE_DOCUMENT_MEDIA_TYPES, IMAGE_MEDIA_TYPES} from '../../domain/media/media-type.enum';
import {MediaFormGroup} from '../../domain/media/media.dto';
import {FileSizePipe} from '../../pipes/upload/file-size.pipe';
import {UploadMediaStore} from '../../stores/upload-media.store';
import {LocaleUtil} from '../../utils/locale.util';

@Component({
  selector: 'croisi-edit-basic-media-info',
  standalone: true,
  imports: [DatePipe, FileSizePipe, InputSwitchModule, InputTextModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './edit-basic-media-info.component.html',
  styleUrl: './edit-basic-media-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBasicMediaInfoComponent {
  private localeToken = inject(LOCALE_ID);

  media = input.required<FormGroup<MediaFormGroup>>();
  index = input<number>(0);
  activeLocale = input<string>(LocaleUtil.ofLocaleToken(this.localeToken));

  store = inject(UploadMediaStore);

  get isImageOrDocument() {
    return IMAGE_DOCUMENT_MEDIA_TYPES.includes(this.media().value.type);
  }

  get isImage() {
    return IMAGE_MEDIA_TYPES.includes(this.media().value.type);
  }

  formArrayLocalized(field: keyof MediaFormGroup) {
    return this.media().get(field) as FormArray;
  }

  formArrayFieldLocalizedFormControl(fieldArray: keyof MediaFormGroup) {
    return this.formArrayLocalized(fieldArray)
      .controls.find(f => f.value.lang === this.activeLocale())
      .get('value') as FormControl;
  }
}
