export class CommonMessageLocalized {
  static OK = $localize`Ok`;
  static YES = $localize`Yes`;
  static NO = $localize`No`;
  static EMPTY = $localize`Empty`;
  static UPDATE_CONFIRM_HEADER = $localize`Update confirmation`;
  static DELETE_CONFIRM_HEADER = $localize`Delete Confirmation`;
  static DELETE_CONFIRM = $localize`Are you sure you want to delete this element?`;
  static CREATE = $localize`Create`;
  static CREATED = $localize`Created`;
  static ADD = $localize`Add`;
  static SAVE = $localize`Save`;
  static SAVED = $localize`Saved`;
  static DELETE = $localize`Delete`;
  static DELETED = $localize`Deleted`;
  static ERROR = $localize`Error`;
  static SUCCESS = $localize`Success`;
  static UPDATE = $localize`Update`;
  static PUBLISH = $localize`Publish`;
  static PUBLISHED = $localize`Published`;
  static PREVIEW = $localize`Preview`;
  static EDIT = $localize`Edit`;
  static CANCEL = $localize`Cancel`;
}
