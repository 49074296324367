<div class="grow max-w-full">
  <div class="flex items-center justify-center m-6">
    <div class="flex gap-2">
      @for (medias of images(); track $index) {
        <div class="flex flex-col gap-2 w-fit h-fit" infiniteScrollNearEnd (nearEnd)="store.loadNextPage()">
          @for (media of medias; track media.id) {
            @if (globalStore.iframe()) {
              <croisi-media-preview [media]="media" />
            } @else {
              <croisi-media-preview [media]="media" [routerLink]="['/', 'medias', media.id]">
                <div class="flex flex-col group-hover:visible absolute top-0 left-0 bg-blue-900/60 w-full h-full p-2"
                     [class.invisible]="!cartStore.isInCart(media)">
                  <div class="flex items-center gap-x-2 h-10">
                    <div class="flex gap-x-2 ml-auto">
                      @if (cartStore.isInCart(media)) {
                        <button (click)="removeFromCart($event, media)"
                                pButton pRipple icon="pi pi-cart-minus"
                                class="p-button-rounded bg-emphasis-secondary text-white border-none">
                        </button>
                      } @else {
                        <button *hasRole="'INTERNE'" pButton pRipple icon="pi pi-pencil"
                                [routerLink]="['/', 'medias', media.id]"
                                class="p-button-rounded bg-emphasis-secondary text-white border-none z-10">
                        </button>
                        <button (click)="addToCart($event, media)"
                                pButton pRipple icon="pi pi-cart-plus"
                                class="p-button-rounded bg-emphasis-secondary text-white border-none">
                        </button>
                      }
                    </div>
                  </div>

                  <p
                    class="mt-auto text-xs text-center px-2 text-white whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {{ localizedField(media, 'name') }}
                  </p>
                </div>
              </croisi-media-preview>
            }
          }
        </div>
      }
    </div>
  </div>
</div>
