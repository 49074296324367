import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {CommonMessageLocalized} from '@i18n/common-message-localized';
import {ButtonDirective} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {PasswordModule} from 'primeng/password';
import {Ripple} from 'primeng/ripple';
import {RegisterRequest, RegisterRequestForm} from '../core/domain/auth/register-request';
import {AuthService} from '../core/services/auth.service';

@Component({
  selector: 'croisi-register',
  standalone: true,
  imports: [ButtonDirective, Ripple, RouterLink, InputTextModule, ReactiveFormsModule, PasswordModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent {
  protected readonly CommonMessageLocalized = CommonMessageLocalized;
  private fb = inject(FormBuilder);
  form = this.fb.group<RegisterRequestForm>({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    company: new FormControl('', [Validators.required]),
  });
  private authService = inject(AuthService);

  cancel() {}

  save() {
    const request = Object.assign(new RegisterRequest(), this.form.value);

    this.authService.register(request).subscribe(() => {});
  }
}
