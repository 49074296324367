import {HttpClient} from '@angular/common/http';
import {inject, Injectable, signal} from '@angular/core';
import {catchError, EMPTY, finalize, first, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {SearchFilterDto} from '../domain/search/search-filter.dto';

@Injectable({
  providedIn: 'root',
})
export class SearchFilterService {
  static WS_PATH = 'filters';

  protected http = inject(HttpClient);

  protected _loading = signal(false);

  getAll(lang: string): Observable<SearchFilterDto[]> {
    this._loading.set(true);
    return this.http.get<SearchFilterDto[]>(environment.api.bff + `/${SearchFilterService.WS_PATH}/${lang}`).pipe(
      first(),
      catchError(() => EMPTY),
      finalize(() => this._loading.set(false))
    );
  }
}
