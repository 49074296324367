<div class="relative flex justify-center items-center h-screen w-screen bg-blue-900/60">
  <img class="absolute w-full h-full object-cover mix-blend-overlay" src="assets/img/background.jpg">
  <div
    class="absolute left-0 bg-blue-900 border border-solid border-l-0 border-white z-10 w-fit rounded-r-2xl px-4 py-8">
    <img height="80" src="assets/img/logo_croisitek.svg" width="200">
  </div>

  <div class="flex flex-col items-center justify-center bg-grey-50 rounded-2xl w-fit mx-auto p-8 z-10 gap-4">
    <span class="uppercase tracking-wide text-blue-900 font-medium text-center" i18n>Create new account</span>

    <form [formGroup]="form" class="grid grid-cols-2 gap-2">
      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="lastname" i18n>Lastname<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="family-name" class="w-full rounded-md" formControlName="lastname" id="lastname"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="address" i18n>Address<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="address-line1" class="w-full rounded-md" formControlName="address" id="address"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="firstname" i18n>Firstname<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="given-name" class="w-full rounded-md" formControlName="firstname" id="firstname"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="zipcode" i18n>Zipcode<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="postal-code" class="w-full rounded-md" formControlName="zipcode" id="zipcode"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="email" i18n>Email<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="email" class="w-full rounded-md" formControlName="email" id="email"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="city" i18n>City<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="address-level2" class="w-full rounded-md" formControlName="city" id="city"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="country" i18n>Country<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="country-name" class="w-full rounded-md" formControlName="country" id="country"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="phone" i18n>Phone<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="tel" class="w-full rounded-md" formControlName="phone" id="phone"
               pInputText />
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="text-blue-900 font-semibold text-xs" for="company" i18n>Company<sup
          class="text-emphasis-primary text-lg">*</sup></label>
        <input autocomplete="organization-title" class="w-full rounded-md" formControlName="company" id="company"
               pInputText />
      </div>
    </form>

    <div class="grid grid-cols-2 gap-4 w-full">
      <button class="p-button-rounded bg-white text-emphasis-cancelling border-emphasis-cancelling justify-center" pButton pRipple
              routerLink="/">
        {{ CommonMessageLocalized.CANCEL }}
      </button>
      <button (click)="save()" [disabled]="form.invalid" class="p-button-rounded p-button-info justify-center" i18n
              pButton pRipple>
        Register
      </button>
    </div>
  </div>

  <img class="absolute right-10 bottom-0" height="150" src="assets/img/logo_croisi_blanc.png" width="150">
</div>
