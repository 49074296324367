import {Injectable, signal} from '@angular/core';
import {MimeTypeData} from '../domain/media/authorized-mime-type-enum';

@Injectable({
  providedIn: 'root',
})
export class UploadMediaStore {
  private _uploadedMedias = signal<any[]>([]);

  get mimeTypes() {
    return Object.entries(MimeTypeData)
      .map(([key, info]) => info.mimeType)
      .join(',');
  }

  get uploadedMedias() {
    return this._uploadedMedias();
  }

  getUploadedFile(index: number): any {
    return this.uploadedMedias.at(index);
  }

  async setUploadedMedias(files: any[]) {
    this._uploadedMedias.set([...files]);
  }
}
