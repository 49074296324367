import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, input, LOCALE_ID, model} from '@angular/core';
import {FormArray, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {PaginatorModule} from 'primeng/paginator';
import {EditBasicMediaInfoComponent} from '../../core/components/edit-basic-media-info/edit-basic-media-info.component';
import {EditCharacteristicsMediaInfoComponent} from '../../core/components/edit-characteristics-media-info/edit-characteristics-media-info.component';
import {SvgComponent} from '../../core/components/svg/svg.component';
import {DOCUMENT_MEDIA_TYPES, VIDEO_MEDIA_TYPES} from '../../core/domain/media/media-type.enum';
import {MediaFormGroup} from '../../core/domain/media/media.dto';
import {MultiLangValueDto} from '../../core/domain/media/multi-lang-value.dto';
import {UploadMediaStore} from '../../core/stores/upload-media.store';
import {LocaleUtil} from '../../core/utils/locale.util';

@Component({
  selector: 'croisi-upload-media-settings',
  standalone: true,
  imports: [
    DatePipe,
    InputSwitchModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    SvgComponent,
    EditBasicMediaInfoComponent,
    EditCharacteristicsMediaInfoComponent,
  ],
  templateUrl: './upload-media-settings.component.html',
  styleUrl: './upload-media-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadMediaSettingsComponent {
  store = inject(UploadMediaStore);
  medias = input.required<FormArray<FormGroup<MediaFormGroup>>>();
  selectedMedia = model.required<number>();
  protected readonly DOCUMENT_MEDIA_TYPES = DOCUMENT_MEDIA_TYPES;
  protected readonly VIDEO_MEDIA_TYPES = VIDEO_MEDIA_TYPES;
  private localeToken = inject(LOCALE_ID);

  get media(): FormGroup<MediaFormGroup> {
    return this.medias().at(this.selectedMedia());
  }

  selectMedia(index: number) {
    this.selectedMedia.set(index);
  }

  localized(media: FormGroup<MediaFormGroup>, field: keyof MediaFormGroup): MultiLangValueDto[] {
    return media.get(field).value as MultiLangValueDto[];
  }

  localizedField(media: FormGroup<MediaFormGroup>, field: keyof MediaFormGroup) {
    const language = LocaleUtil.ofLocaleToken(this.localeToken);

    return this.localized(media, field).find(f => f.lang === language).value;
  }
}
