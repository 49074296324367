<div class="flex flex-col min-w-[64rem]">
  <div class="flex justify-between w-full bg-blue-900 py-4 px-6 rounded-t-2xl h-24">
    <div class="text-white">
      <p class="mt-0 font-bold" i18n>Media Settings</p>
      @if (!mixedMedia() && !videoMedia()) {
        <p-checkbox [(ngModel)]="bulkEdit" [binary]="true" />
        <label class="ml-3" i18n>Bulk Edit</label>
      }
    </div>
    <i (click)="cancel()" class="pi pi-times text-2xl text-emphasis-primary cursor-pointer"></i>
  </div>

  <div class="grow bg-white min-w-[64rem] h-[36rem] mb-16">
    @if (medias.length > 0) {
      @if (bulkEdit()) {
        <croisi-upload-media-settings-bulk-edit [medias]="medias" />
      } @else {
        <croisi-upload-media-settings [medias]="medias" [(selectedMedia)]="selectedMedia" />
      }
    }
  </div>

  <div
    class="fixed bottom-0 left-0 w-full h-16 flex items-center bg-white rounded-2xl gap-x-4 drop-shadow-2xl p-4">
    @if (selectedMedia() > 0) {
      <button (click)="previousMedia()" pButton pRipple class="p-button-text text-emphasis-secondary">
        <i class="pi pi-angle-left text-emphasis-secondary"></i>
        <span class="ml-2" i18n>Previous media</span>
      </button>
    }

    @if (uploading()) {
      <div class="flex items-center gap-2 ml-auto">
        <p-progressBar styleClass="w-36"
                       [value]="progress()"
                       [showValue]="false"
                       [style]="{ height: '4px' }" />
        <label class="text-right text-xs text-emphasis-secondary" i18n>
          {{ progress() }}% uploaded...
        </label>
      </div>
    }

    <button (click)="cancel()" class="p-button-rounded bg-white text-emphasis-cancelling border-emphasis-cancelling ml-auto" i18n
            pButton
            pRipple>
      {{ CommonMessageLocalized.CANCEL }}
    </button>
    <button (click)="save()" [disabled]="medias.invalid || uploading()" class="p-button-rounded p-button-info" pButton
            pRipple>
      <span i18n>{{ CommonMessageLocalized.SAVE }}</span>
    </button>

    @if (selectedMedia() < medias.length - 1) {
      <button (click)="nextMedia()" pButton pRipple class="p-button-text text-emphasis-secondary">
        <span class="mr-2" i18n>Next media</span>
        <i class="pi pi-angle-right text-emphasis-secondary"></i>
      </button>
    }
  </div>
</div>
