import {FormControl} from '@angular/forms';

export class RegisterRequest {
  email: string;
  firstname: string;
  lastname: string;
  address: string;
  zipcode: string;
  city: string;
  country: string;
  company: string;
  phone: string;
}

export interface RegisterRequestForm {
  email: FormControl<string>;
  firstname: FormControl<string>;
  lastname: FormControl<string>;
  address: FormControl<string>;
  zipcode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
  company: FormControl<string>;
  phone: FormControl<string>;
}
