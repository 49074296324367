import {NgIf} from '@angular/common';
import {Directive, inject, input, OnInit} from '@angular/core';
import {CurrentUser} from '../../services/current-user';
import {MedialibRole, REALM_ROLES} from './role.types';

@Directive({
  selector: '[hasRole]',
  hostDirectives: [NgIf],
  standalone: true,
})
export class HasRoleDirective implements OnInit {
  roles = input.required<MedialibRole | MedialibRole[]>({
    alias: 'hasRole',
  });

  private currentUser = inject(CurrentUser);
  private ngIfDirective = inject(NgIf);

  ngOnInit() {
    const role = this.roles();

    if (Array.isArray(role)) {
      const realmRoles = role.map(r => REALM_ROLES[r]);

      this.ngIfDirective.ngIf = this.currentUser.hasRole(realmRoles, this.currentUser.user);
    } else {
      const realmRole = REALM_ROLES[role];

      this.ngIfDirective.ngIf = this.currentUser.hasRole([realmRole], this.currentUser.user);
    }
  }
}
