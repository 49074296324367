import {FormControl} from '@angular/forms';
import {MediaTypeEnum} from './media-type.enum';

export class MediaSearchDto {
  globalSearch: string;
  types: MediaTypeEnum[];
  properties: string[];
  regionCodes: string[];
  countryCodes: string[];
  riverCodes: string[];
  seaCodes: string[];
  channelCodes: string[];
  boats: string[];
  excursion: boolean;
  internal: boolean;
  boatRelated: boolean;
  createdByMe: boolean;
  createdAfter: string;
  createdBefore: string;
  createdBy: string;
  page: number;
  size: number;
  sort: string;
}

export interface MediaSearchForm {
  globalSearch: FormControl<string>;
  types: FormControl<MediaTypeEnum[]>;
  properties: FormControl<string[]>;
  regionCodes: FormControl<string[]>;
  countryCodes: FormControl<string[]>;
  riverCodes: FormControl<string[]>;
  seaCodes: FormControl<string[]>;
  channelCodes: FormControl<string[]>;
  boats: FormControl<string[]>;
  excursion: FormControl<boolean>;
  internal: FormControl<boolean>;
  boatRelated: FormControl<boolean>;
  createdByMe: FormControl<boolean>;
  createdAfter: FormControl<Date>;
  createdBefore: FormControl<Date>;
  createdBy: FormControl<string>;
  sort: FormControl<string>;
}
