import {HttpClient} from '@angular/common/http';
import {inject, Injectable, signal} from '@angular/core';
import {catchError, EMPTY, finalize, first, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {MediaSearchDto} from '../domain/media/media-search.dto';
import {MediaDto} from '../domain/media/media.dto';
import {UpdateMediaFormatRequest} from '../domain/media/update-media-format-request';
import {HttpParamsBuilder} from '../domain/rest/http-params-builder';
import {PageWithBuckets} from '../domain/rest/page-with-buckets';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  static WS_PATH = 'medias';

  protected http = inject(HttpClient);

  protected _loading = signal(false);

  get loading() {
    return this._loading();
  }

  buildQueryParams(search: MediaSearchDto): string {
    const builder = new HttpParamsBuilder();

    builder.appendAll(search);

    return builder.build().toString();
  }

  search(search: MediaSearchDto): Observable<PageWithBuckets<MediaDto>> {
    const builder = new HttpParamsBuilder();

    builder.appendAll(search);

    this._loading.set(true);
    return this.http
      .get<PageWithBuckets<MediaDto>>(environment.api.bff + `/${MediaService.WS_PATH}`, {
        params: builder.build(),
      })
      .pipe(
        first(),
        catchError(() => EMPTY),
        finalize(() => this._loading.set(false))
      );
  }

  upload(file: any, media: MediaDto): Observable<MediaDto> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mediaDto', new Blob([JSON.stringify(media)], {type: 'application/json'}));

    return this.http.post<MediaDto>(environment.api.bff + `/${MediaService.WS_PATH}`, formData).pipe(
      first(),
      catchError(() => EMPTY)
    );
  }

  get(id: string): Observable<MediaDto> {
    this._loading.set(true);
    return this.http.get<MediaDto>(environment.api.bff + `/${MediaService.WS_PATH}/${id}/metadata`).pipe(
      first(),
      catchError(() => EMPTY),
      finalize(() => this._loading.set(false))
    );
  }

  delete(id: string) {
    this._loading.set(true);
    return this.http.delete<void>(environment.api.bff + `/${MediaService.WS_PATH}/${id}`).pipe(
      first(),
      catchError(() => EMPTY),
      finalize(() => this._loading.set(false))
    );
  }

  crop(id: string, request: UpdateMediaFormatRequest) {
    this._loading.set(true);
    return this.http.put<void>(environment.api.bff + `/${MediaService.WS_PATH}/${id}/reframe`, request).pipe(
      first(),
      catchError(() => EMPTY),
      finalize(() => this._loading.set(false))
    );
  }

  update(id: string, file?: any, media?: MediaDto) {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }
    if (media) {
      formData.append('mediaDto', new Blob([JSON.stringify(media)], {type: 'application/json'}));
    }

    return this.http.put<MediaDto>(environment.api.bff + `/${MediaService.WS_PATH}/${id}`, formData).pipe(
      first(),
      catchError(() => EMPTY)
    );
  }
}
