import {ChangeDetectionStrategy, Component, inject, input, LOCALE_ID, OnInit} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {PrimeTemplate} from 'primeng/api';
import {AutoCompleteCompleteEvent, AutoCompleteModule} from 'primeng/autocomplete';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {PaginatorModule} from 'primeng/paginator';
import {Subject} from 'rxjs';
import {
  DOCUMENT_MEDIA_TYPES,
  LOCALIZED_MEDIA_TYPES,
  MediaTypeData,
  MediaTypeEnum,
  UNFRAMABLE_MEDIA_TYPES,
  VIDEO_MEDIA_TYPES,
} from '../../domain/media/media-type.enum';
import {MediaFormGroup} from '../../domain/media/media.dto';
import {ReferentialStore} from '../../stores/referential.store';
import {UploadMediaStore} from '../../stores/upload-media.store';
import {LocaleUtil} from '../../utils/locale.util';
import {SvgComponent} from '../svg/svg.component';

@Component({
  selector: 'croisi-edit-characteristics-media-info',
  standalone: true,
  imports: [
    AutoCompleteModule,
    DropdownModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    PaginatorModule,
    PrimeTemplate,
    SvgComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './edit-characteristics-media-info.component.html',
  styleUrl: './edit-characteristics-media-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCharacteristicsMediaInfoComponent implements OnInit {
  private localeToken = inject(LOCALE_ID);

  media = input.required<FormGroup<MediaFormGroup>>();
  index = input<number>(0);

  store = inject(UploadMediaStore);
  referentialStore = inject(ReferentialStore);
  tagTerm = new Subject<string>();
  propertyTerm = new Subject<string>();

  constructor() {
    const language = LocaleUtil.ofLocaleToken(this.localeToken);

    this.referentialStore.load(language);
  }

  get mediaTypeByFileType(): MediaTypeEnum[] {
    if (DOCUMENT_MEDIA_TYPES.includes(this.media().value.type)) {
      return DOCUMENT_MEDIA_TYPES;
    }

    if (VIDEO_MEDIA_TYPES.includes(this.media().value.type)) {
      return VIDEO_MEDIA_TYPES;
    }

    if (UNFRAMABLE_MEDIA_TYPES.includes(this.media().value.type)) {
      return UNFRAMABLE_MEDIA_TYPES;
    }

    return [MediaTypeEnum.PHOTO];
  }

  get isLocalizedMediaType() {
    return LOCALIZED_MEDIA_TYPES.includes(this.media().value.type);
  }

  get isVideoType() {
    return VIDEO_MEDIA_TYPES.includes(this.media().value.type);
  }

  ngOnInit() {
    this.media().controls.type.valueChanges.subscribe(() => {
      this.media().controls.lang.patchValue('');
    });

    this.tagTerm.subscribe(search => this.referentialStore.autocomplete('TAGS', search));
    this.propertyTerm.subscribe(search => this.referentialStore.autocomplete('PROPERTY', search));
  }

  getMediaDataByType(type: MediaTypeEnum) {
    return MediaTypeData[type];
  }

  search(referential: 'PROPERTY' | 'TAGS', event: AutoCompleteCompleteEvent) {
    switch (referential) {
      case 'TAGS':
        this.tagTerm.next(event.query);
        break;
      case 'PROPERTY':
        this.propertyTerm.next(event.query);
        break;
    }
  }
}
