<p-dialog [modal]="true" [visible]="cropping()" styleClass="w-10/12 h-5/6">
  <ng-template pTemplate="headless">
    <div class="flex flex-col rounded-t-2xl bg-white h-full w-full">
      <div class="flex items-center justify-between w-full bg-blue-900 rounded-t-2xl px-6 py-4">
        <span class="font-bold text-white" i18n>Cropping</span>
        <i (click)="close()" class="pi pi-times text-2xl text-emphasis-primary cursor-pointer"></i>
      </div>

      <image-cropper (imageCropped)="imageCropped($event)" [aspectRatio]="ratio"
                     [autoCrop]="true"
                     [cropperMinHeight]="minCropperSize.minHeight"
                     [cropperMinWidth]="minCropperSize.minWidth"
                     [imageURL]="url"
                     [maintainAspectRatio]="true"
                     class="mb-16" />

      <div
        class="fixed bottom-0 left-0 w-full h-16 flex items-center bg-white rounded-2xl gap-x-4 drop-shadow-2xl p-4">
        <button (click)="close()" class="p-button-rounded bg-white text-emphasis-cancelling border-emphasis-cancelling ml-auto" i18n
                pButton
                pRipple>
          {{ CommonMessageLocalized.CANCEL }}
        </button>
        <button (click)="crop()" [disabled]="loading" class="p-button-rounded p-button-info" i18n
                pButton pRipple>
          Crop
          @if (loading) {
            <i class="ml-2 pi pi-spin pi-spinner"></i>
          }
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>

