<div class="flex items-center justify-center relative group cursor-pointer w-fit">
  @if (DOCUMENT_MEDIA_TYPES.includes(media().type)) {
    <div class="flex items-center justify-center h-full w-full bg-grey-50" [ngClass]="previewWidthClass">
      <croisi-svg class="h-36 w-36 text-red-500" namespace="bo-icons" icon="ce-document" />
    </div>
  } @else if (VIDEO_MEDIA_TYPES.includes(media().type)) {
    <div class="relative flex items-center justify-center h-full w-full">
      <img [src]="MediaUtil.previewUrl(media())" class="object-cover aspect-auto" [width]="previewWidth">
      <i class="absolute text-9xl pi pi-youtube text-white"></i>
    </div>
  } @else {
    <img [src]="MediaUtil.previewUrl(media())" class="object-contain aspect-auto" [width]="previewWidth">
  }

  <ng-content />
</div>
