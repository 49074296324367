import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, inject, LOCALE_ID, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {parse} from 'date-fns';
import {SelectItem} from 'primeng/api';
import {AutoCompleteCompleteEvent, AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonDirective} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {GalleryComponent} from '../core/components/gallery/gallery.component';
import {NavigationComponent} from '../core/components/navigation/navigation.component';
import {SvgComponent} from '../core/components/svg/svg.component';
import {HasRoleDirective} from '../core/directives/security/has-role.directive';
import {MediaSearchDto, MediaSearchForm} from '../core/domain/media/media-search.dto';
import {MediaTypeData, MediaTypeEnum} from '../core/domain/media/media-type.enum';
import {GlobalStore} from '../core/stores/global.store';
import {MediaStore} from '../core/stores/media.store';
import {ReferentialStore} from '../core/stores/referential.store';
import {ObjectUtil} from '@030_croisieurope/croisi-front-library';

@Component({
  selector: 'croisi-search',
  standalone: true,
  imports: [
    NavigationComponent,
    GalleryComponent,
    ButtonDirective,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    DropdownModule,
    SvgComponent,
    InputSwitchModule,
    MultiSelectModule,
    InputTextModule,
    HasRoleDirective,
    AutoCompleteModule,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MediaStore],
})
export class SearchComponent implements OnInit {
  store = inject(MediaStore);
  referentialStore = inject(ReferentialStore);
  globalStore = inject(GlobalStore);
  tagTerm = new Subject<string>();
  propertyTerm = new Subject<string>();
  availableSorts: SelectItem[] = [
    {
      value: '-createdAt',
      label: $localize`Sort by most recent upload`,
    },
    {
      value: '+createdAt',
      label: $localize`Sort by least recent upload`,
    },
  ];
  protected readonly window = window;
  private fb = inject(FormBuilder);
  queryForm = this.fb.group<MediaSearchForm>({
    globalSearch: new FormControl(undefined),
    types: new FormControl([]),
    properties: new FormControl([]),
    regionCodes: new FormControl([]),
    countryCodes: new FormControl([]),
    riverCodes: new FormControl([]),
    seaCodes: new FormControl([]),
    channelCodes: new FormControl([]),
    boats: new FormControl([]),
    excursion: new FormControl(undefined),
    internal: new FormControl(undefined),
    boatRelated: new FormControl(undefined),
    createdByMe: new FormControl(undefined),
    createdAfter: new FormControl(undefined),
    createdBefore: new FormControl(undefined),
    createdBy: new FormControl(undefined),
    sort: new FormControl(undefined),
  });
  private route = inject(ActivatedRoute);
  private location = inject(Location);

  constructor(@Inject(LOCALE_ID) localeToken: string) {
    const language = localeToken.split('-')[0].toUpperCase();

    this.referentialStore.load(language);

    this.queryForm.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(values => this.search(values));

    this.tagTerm.subscribe(search => this.referentialStore.autocomplete('TAGS', search, true));
    this.propertyTerm.subscribe(search => this.referentialStore.autocomplete('PROPERTY', search, true));
  }

  get mediaTypes(): MediaTypeEnum[] {
    return Object.values(MediaTypeEnum);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.queryForm.patchValue({
        ...params,
        types: this.translateQueryParamArrayToForm(params['types']),
        properties: this.translateQueryParamArrayToForm(params['properties']),
        regionCodes: this.translateQueryParamArrayToForm(params['regionCodes']),
        countryCodes: this.translateQueryParamArrayToForm(params['countryCodes']),
        riverCodes: this.translateQueryParamArrayToForm(params['riverCodes']),
        seaCodes: this.translateQueryParamArrayToForm(params['seaCodes']),
        channelCodes: this.translateQueryParamArrayToForm(params['channelCodes']),
        boats: this.translateQueryParamArrayToForm(params['boats']),
        excursion: this.translateQueryParamBooleanToForm(params['excursion']),
        internal: this.translateQueryParamBooleanToForm(params['internal']),
        boatRelated: this.translateQueryParamBooleanToForm(params['boatRelated']),
        createdByMe: this.translateQueryParamBooleanToForm(params['createdByMe']),
        createdAfter: this.translateQueryParamDateToForm(params['createdAfter']),
        createdBefore: this.translateQueryParamDateToForm(params['createdBefore']),
      });
    });
  }

  search(values: any) {
    const search = Object.assign(new MediaSearchDto(), {
      ...values,
      createdAfter: ObjectUtil.isNonNullOrUndefined(values.createdAfter)
        ? values.createdAfter.toLocaleDateString()
        : undefined,
      createdBefore: ObjectUtil.isNonNullOrUndefined(values.createdBefore)
        ? values.createdBefore.toLocaleDateString()
        : undefined,
    });

    this.store.searchMedias(search);

    this.location.replaceState('search', this.store.queryParams);

    window.scroll({top: 0, behavior: 'smooth'});
  }

  getMediaDataByType(type: MediaTypeEnum) {
    return MediaTypeData[type];
  }

  reset() {
    this.queryForm.reset();
  }

  searchReferential(referential: 'PROPERTY' | 'TAGS', event: AutoCompleteCompleteEvent) {
    switch (referential) {
      case 'TAGS':
        this.tagTerm.next(event.query);
        break;
      case 'PROPERTY':
        this.propertyTerm.next(event.query);
        break;
    }
  }

  private translateQueryParamArrayToForm(value: any | any[]) {
    if (ObjectUtil.isNullOrUndefined(value)) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  }

  private translateQueryParamDateToForm(value: string) {
    if (ObjectUtil.isNullOrUndefined(value)) {
      return null;
    }

    return parse(value, 'dd/MM/yyyy', new Date());
  }

  private translateQueryParamBooleanToForm(value: string) {
    if (ObjectUtil.isNullOrUndefined(value)) {
      return null;
    }

    return value === 'true';
  }
}
